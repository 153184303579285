//local imports
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { StateService } from 'src/app/services/state.service';
import { WebService } from 'src/app/services/web.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { TABLE_SIZE_OPTIONS, PURCHASEORDER_STATUS_MAP, COUNTRY_MAP } from 'src/app/utils/constants';

//ng-zorro imports
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Product, Brand, Category, Supplier } from 'src/app/models/product';
import { RowSettings } from 'src/app/models/row-setting';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzTableSize } from 'ng-zorro-antd/table';
import { stat } from 'fs';
import { Console, count } from 'console';
import { CacheService } from 'src/app/services/cache.service';
import { PermissionService } from 'src/app/services/permission.service';
import { OrderItem } from 'src/app/models/order';
import { ShopifyProductSync, CreateShopifyProductSyncDto } from 'src/app/models/shopify-product-sync';

//define data structres
interface SearchParam {
  product_name: string;
  barcode: string;
  status: string;
  brand_name: string;
  buyer_id: string;
  country: string[];
  brand_list: string[];
  first_level_name: string[];
  second_level_name: string[];
  third_level_name: string[];
  purchase_date_range: [Date, Date] | null;
  supplier_name: string[];
  expiry_date_range: [Date, Date] | null;
  stock_action: '',  // 添加庫存操作過濾選項
}

interface TransferProduct {
  name: string;
  id: string;
  shopify_id: string;
  actual_transfer_quantity: number;
  transfer_date?: string;
}

interface LocationInventory {
  locationName: string;
  available: number;
}

interface DailySales {
  date: string;
  quantity: number;
}

interface LocationDailySales {
  date: string;
  quantity: number;
  location_id: number;
}


interface ProductWithInventory extends Product {
  inventories?: Array<{
    available: number;
    location: {
      name_zh: string;
      address_zh: string;
    };
  }>;
  total_inventory?: number;
  warehouse_inventory?: number; // 新增仓库库存属性
  last_week_sales?: number;
  location_inventories?: LocationInventory[];
  locations_with_inventory?: number;
 
}

//html routing
@Component({
  selector: 'app-products-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})

//component class
export class ProductsComponent implements OnInit {
  all_products: Product[] = [];
  all_productNames: string[] = [];
  all_productBarcodes: string[] = [];

  selectedProduct: Product | undefined;
  isDeleteModalVisible = false;
  isBatchDeleteModalVisible = false;
  showSyncButton = false;
  disableForm: FormGroup;

  roleId: number | undefined;
  userId: number | undefined;
  user: any;
  ProductList: ProductWithInventory[] = [];
  ProductDetail: Product[] = [];
  ProductListFiltered: ProductWithInventory[] = [];
  supplierFilterOptions: string[] = [];
  isSpinning = false;
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: readonly Product[] = [];
  setOfCheckedId = new Set<number>();
  inventoryPopoverMap = new Map<string, boolean>();
  salesDetailsMap = new Map<number, DailySales[]>();
  salesLocationDetailsMap = new Map<number, LocationDailySales[]>();
  locationAveragesMap = new Map<string, number>();
  supplierProductIds: number[] = [];

  searchParam: SearchParam = {
    product_name: '',
    barcode: '',
    status: '',
    brand_name: '',
    buyer_id: '',
    country: [],
    brand_list: [],
    first_level_name: [],
    second_level_name: [],
    third_level_name: [],
    purchase_date_range: null as [Date, Date] | null,
    supplier_name: [],
    expiry_date_range: null as [Date, Date] | null,
    stock_action: '',
  };
  // for filtering
  totalCost = 0;
  productNames: Set<string> = new Set<string>();
  barcodes: Set<string> = new Set<string>();
  statuses: Set<string> = new Set<string>();
  brands: Set<string> = new Set<string>();
  brandsList: Set<string> = new Set<string>();
  countries: Set<string> = new Set<string>();
  first_level: Set<string> = new Set<string>();
  second_level: Set<string> = new Set<string>();
  third_level: Set<string> = new Set<string>();
  buyer_set: Set<string> = new Set<string>();
  // listOfOption: Array<{ value: string; label: string }> = [];
  // listOfSelectedValue = [];

  productNameFilterOptions: string[] = [];
  barcodesFilterOptions: string[] = [];
  statusFilterOptions: string[] = [];
  brandFilterOptions: string[] = [];
  countryFilterOptions: string[] = [];
  // allCountries: string[] = [];
  brandListFilterOptions: string[] = [];
  firstLevelFilterOptions: string[] = [];
  secondLevelFilterOptions: string[] = [];
  thirdLevelFilterOptions: string[] = [];
  buyerFilterOptions: string[] = [];
  settingsVisible = false;
  isVisible = false; //modal visibility for addUser()
  editIsVisible = false; //modal visibility for editUser()
  resetIsVisible = false; //modal visibility for resetUserPassword()
  locations: any[] = [];
  selectedIndex: number = 0;
  tableSize: NzTableSize = 'middle';
  TABLE_SIZE_OPTIONS = TABLE_SIZE_OPTIONS;
   // 添加控制操作列顯示的屬性
  showOperationsColumn: boolean = false;

  selectedStatus: string = '';
  reason: string = '';
  isPopoverVisible: boolean = false;
  currentPurchaseOrderId: number | null = null;
  isLocationOrderLoading = false;

  productSalesLastSevenDays: Map<
    string,
    Array<{
      transfer_date: string;
      actual_transfer_quantity: number;
    }>
  > = new Map();
  isSalesDataLoading: boolean = false;
  transferDetails: Map<string, Array<{ transfer_date: string; actual_transfer_quantity: number }>> = new Map();
  isTransferDataLoading: boolean = false;
  isInventoryDataLoading: boolean = false;
  

  loadingInventory = false;

  //table settings
  Product_default_rows: RowSettings[] = [
    <RowSettings>{
      name: '編號',
      key: 'id',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.id - b.id,
    },
    <RowSettings>{
      name: '產品名稱',
      key: 'name',
      show: true,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.name.localeCompare(b.name) ?? false,
    },
    <RowSettings>{
      name: '產品簡介',
      key: 'description',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.description?.localeCompare(b.description) ?? false,
    },
    <RowSettings>{
      name: '條碼',
      key: 'barcode',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.barcode?.localeCompare(b.barcode) ?? false,
    },
    <RowSettings>{
      name: '成本',
      key: 'cost',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.cost - b.cost,
    },
    <RowSettings>{
      name: '零售價格',
      key: 'price',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.price - b.price,
    },
    <RowSettings>{
      name: '比較價格',
      key: 'compare_at_price',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.compare_at_price - b.compare_at_price,
    },
    <RowSettings>{
      name: '毛利',
      key: 'gross_margin',
      show: false,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.gross_margin - a.gross_margin - (b.gross_margin - b.gross_margin),
    },
    <RowSettings>{
      name: '外箱規格',
      key: 'box_spec_quantity',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => (a.box_spec_quantity ?? 0) - (b.box_spec_quantity ?? 0),
    },
    <RowSettings>{
      name: '到期日',
      key: 'expiry_date',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) =>
        (a.expiry_date?.toString() ?? '').localeCompare(b.expiry_date?.toString() ?? '') ?? false,
    },
    <RowSettings>{
      name: '到期日類型',
      key: 'expiry_date_type',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) =>
        (a.expiry_date_type?.toString() ?? '').localeCompare(b.expiry_date_type?.toString() ?? '') ?? false,
    },
    <RowSettings>{
      name: '品牌',
      key: 'brand_name',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const nameA = a.brand?.name || '';
        const nameB = b.brand?.name || '';
        return nameA.localeCompare(nameB);
      },
    },
    <RowSettings>{
      name: '一級分類',
      key: 'first_level_name',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const nameA = a.category?.first_level_name || '';
        const nameB = b.category?.first_level_name || '';
        return nameA.localeCompare(nameB);
      },
    },
    <RowSettings>{
      name: '二級分類',
      key: 'second_level_name',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const nameA = a.category?.second_level_name || '';
        const nameB = b.category?.second_level_name || '';
        return nameA.localeCompare(nameB);
      },
    },
    <RowSettings>{
      name: '三級分類',
      key: 'third_level_name',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const nameA = a.category?.third_level_name || '';
        const nameB = b.category?.third_level_name || '';
        return nameA.localeCompare(nameB);
      },
    },
    <RowSettings>{
      name: '產地',
      key: 'country',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const nameA = a.country?.name_zh || a.country?.name_en || a.country_name || '';
        const nameB = b.country?.name_zh || b.country?.name_en || b.country_name || '';
        return nameA.localeCompare(nameB);
      },
      format: (product: Product) => {
        return product.country?.name_zh || product.country?.name_en || product.country_name || '';
      },
    },
    <RowSettings>{
      name: '最近採購日期',
      key: 'purchase_date',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const dateA = a.latest_purchase?.purchase_date ? new Date(a.latest_purchase.purchase_date) : new Date(0);
        const dateB = b.latest_purchase?.purchase_date ? new Date(b.latest_purchase.purchase_date) : new Date(0);
        return dateA.getTime() - dateB.getTime();
      },
      format: (product: Product) =>
        product.latest_purchase?.purchase_date
          ? new Date(product.latest_purchase.purchase_date).toLocaleDateString()
          : '-',
    },
    <RowSettings>{
      name: '供應商',
      key: 'supplier_name',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        // 獲取供應商名稱
        const zhNameA = a.latest_purchase?.supplier_name_zh || '';
        const enNameA = a.latest_purchase?.supplier_name_en || '';
        const zhNameB = b.latest_purchase?.supplier_name_zh || '';
        const enNameB = b.latest_purchase?.supplier_name_en || '';
        
        // 優先使用中文名稱進行排序
        if (zhNameA && zhNameB) {
          return zhNameA.localeCompare(zhNameB);
        } else if (zhNameA) {
          return -1; // 有中文名稱的排在前面
        } else if (zhNameB) {
          return 1;
        } else {
          // 如果都沒有中文名稱，則使用英文名稱排序
          return enNameA.localeCompare(enNameB);
        }
      },
      format: (product: Product) => {
        const zhName = product.latest_purchase?.supplier_name_zh;
        const enName = product.latest_purchase?.supplier_name_en;
        
        // 如果中英文名稱都存在，則顯示為 "中文名稱/英文名稱" 的格式
        if (zhName && enName) {
          return `${zhName}/${enName}`;
        }
        // 如果只有中文名稱，則只顯示中文名稱
        else if (zhName) {
          return zhName;
        }
        // 如果只有英文名稱，則只顯示英文名稱
        else if (enName) {
          return enName;
        }
        // 如果都沒有，則顯示 '-'
        return '-';
      },
    },
    <RowSettings>{
      name: '採購員',
      key: 'buyer_name',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) =>
        (a.buyer_name?.toString() ?? '').localeCompare(b.buyer_name?.toString() ?? '') ?? false,
    },
    <RowSettings>{
      name: '最近採購數量',
      key: 'last_purchase_quantity',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const qtyA = a.latest_purchase?.quantity || 0;
        const qtyB = b.latest_purchase?.quantity || 0;
        return qtyA - qtyB;
      },
      format: (product: Product) =>
        product.latest_purchase?.quantity?.toString() || '-',
    },
    <RowSettings>{
      name: '庫存',
      key: 'total_inventory',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => (a.total_inventory || 0) - (b.total_inventory || 0),
      custom: true,
      width: '100px'
    },
    <RowSettings>{
      name: '倉存',
      key: 'warehouse_inventory',
      show: false,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: ProductWithInventory, b: ProductWithInventory) =>
        (a.warehouse_inventory || 0) - (b.warehouse_inventory || 0),
      format: (product: ProductWithInventory) =>
        product.warehouse_inventory?.toString() || '0',
      width: '100px'
    },
    <RowSettings>{
      name: '庫存價值',
      key: 'inventory_value',
      show: false,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const valueA = Math.max(0, (a.total_inventory || 0)) * (a.cost || 0);
        const valueB = Math.max(0, (b.total_inventory || 0)) * (b.cost || 0);
        return valueA - valueB;
      },
      format: (product: Product) => {
        const inventory = Math.max(0, (product.total_inventory || 0));
        const value = inventory * (product.cost || 0);
        return `$${value.toFixed(2)}`;
      },
      width: '120px'
    },
    <RowSettings>{
      name: '長期供貨',
      key: 'has_long_term_supply',
      show: false,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        // Convert to number for proper comparison (true = 1, false = 0)
        const aValue = Number(Boolean(a.has_long_term_supply));
        const bValue = Number(Boolean(b.has_long_term_supply));
        return aValue - bValue;
      },
    },
    // <RowSettings>{
    //   name: '平均銷售量（舊）',
    //   key: 'sales_details',
    //   show: false,
    //   custom: true,
    //   width: '80px',
    //   sortOrder: null,
    //   sortDirections: ['ascend', 'descend', null],
    // },
        <RowSettings>{
      name: '平均銷售量',
      key: 'average_sales',
      show: true,
      custom: true,
      width: '100px',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const aValue = (a as any).average_sales || 0;
        const bValue = (b as any).average_sales || 0;
        return aValue - bValue;
      },
      format: (product: Product) => {
        const value = (product as any).average_sales;
        return value !== undefined ? value.toFixed(2) : '0.00';
      }
    },

    <RowSettings>{
      name: '最近七日總商品售出量（平均）',
      key: 'last_seven_days_sales',
      show: true,
      fixed: false,
      fixedDir: '',
      editable: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null] as NzTableSortOrder[],
      sortFn: (a: Product, b: Product) => {
        const aQuantity = a.last_seven_days_sales || 0;
        const bQuantity = b.last_seven_days_sales || 0;
        return aQuantity - bQuantity;
      },
      format: (product: Product) => this.formatSalesWithAverage(product),
      hint: '',
      custom: true,
      width: '150px',
    },
        <RowSettings>{
      name: '最近七日銷售總額',
      key: 'last_seven_days_sales_amount',
      show: true,
      fixed: false,
      fixedDir: '',
      editable: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null] as NzTableSortOrder[],
      sortFn: (a: Product, b: Product) => {
        const aAmount = (a.last_seven_days_sales || 0) * (a.price || 0);
        const bAmount = (b.last_seven_days_sales || 0) * (b.price || 0);
        return aAmount - bAmount;
      },
      format: (product: Product) => {
        const amount = (product.last_seven_days_sales || 0) * (product.price || 0);
        return `$${amount.toFixed(2)}`;
      },
      hint: '',
      custom: true,
      width: '150px',
    },
    <RowSettings>{
      name: '每日所需銷售量',
      key: 'required_daily_sales',
      show: true,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const rateA = this.calculateRequiredDailySales(a);
        const rateB = this.calculateRequiredDailySales(b);

        // 處理特殊情況的排序
        if (rateA === null && rateB === null) return 0;
        if (rateA === null) return 1;
        if (rateB === null) return -1;
        if (!isFinite(rateA) && !isFinite(rateB)) return 0;
        if (!isFinite(rateA)) return 1;
        if (!isFinite(rateB)) return -1;

        return rateA - rateB;
      },
      format: (product: Product) => {
        const rate = this.calculateRequiredDailySales(product);
        if (rate === null) return '-';
        if (!isFinite(rate)) return '∞';
        return rate.toFixed(1);
      },
      width: '120px'
    },
    <RowSettings>{
      name: '預計銷售日數',
      key: 'estimated_inventory_sales_days',
      show: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: ProductWithInventory, b: ProductWithInventory) => {
        const aDays = this.calculateEstimatedDailySales(a);
        const bDays = this.calculateEstimatedDailySales(b);

        if (aDays === Infinity && bDays === Infinity) {
          return 0;
        }
        if (aDays === Infinity) {
          return -1;
        }
        if (bDays === Infinity) {
          return 1;
        }
        return aDays - bDays;
      },
      format: (product: ProductWithInventory) => {
        const days = this.calculateEstimatedDailySales(product);
        return days === Infinity ? '-' : days.toFixed(1);
      },
      custom: true,
      width: '120px'
    },
    <RowSettings>{
      name: '距離到期日',
      key: 'days_until_expiry',
      show: true,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => {
        const daysA = this.calculateDaysUntilExpiry(a.expiry_date?.toString());
        const daysB = this.calculateDaysUntilExpiry(b.expiry_date?.toString());
        // 在排序时将 null 视为 0
        const valueA = daysA === null ? 0 : daysA;
        const valueB = daysB === null ? 0 : daysB;
        return valueA - valueB;
      },
      format: (product: Product) => {
        const days = this.calculateDaysUntilExpiry(product.expiry_date?.toString());
        if (days === null) return '-';
        if (days < 0) return ` ${days} 日`;
        return `${days} 日`;
      },
    },
    <RowSettings>{
      name: '追貨',
      key: 'restock_action',
      show: true,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: ProductWithInventory, b: ProductWithInventory) => {
        const aNeeds = this.needsRestock(a);
        const bNeeds = this.needsRestock(b);
        // 排序邏輯：需要追貨的排在前面
        return Number(bNeeds) - Number(aNeeds);
      },
      format: (product: ProductWithInventory) => {
        return this.needsRestock(product) ? '✓' : '';
      },
      width: '80px'
    },
    <RowSettings>{
      name: '清貨',
      key: 'clearance_action',
      show: true,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: ProductWithInventory, b: ProductWithInventory) => {
        const aNeeds = this.needsClearance(a);
        const bNeeds = this.needsClearance(b);
        // 排序邏輯：需要清貨的排在前面
        return Number(bNeeds) - Number(aNeeds);
      },
      format: (product: ProductWithInventory) => {
        return this.needsClearance(product) ? '✓' : '';
      },
      width: '80px'
    },
    <RowSettings>{
      name: '最近七日平均配貨出庫數量(總出庫數量)',
      key: 'total_actual_transfer_quantity',
      show: false,
      fixed: false,
      fixedDir: '',
      editable: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null] as NzTableSortOrder[],
      sortFn: (a: Product, b: Product) => {
        const aQuantity = a.total_actual_transfer_quantity || 0;
        const bQuantity = b.total_actual_transfer_quantity || 0;
        return aQuantity - bQuantity;
      },
      format: (product: Product) => {
        const avgDaily = product.average_daily_transfer || 0;
        const total = product.total_actual_transfer_quantity || 0;
        return `${total} (${avgDaily})`;
      },
      hint: '',
      custom: true,
      width: '',
    },
    <RowSettings>{
      name: '採購總次數',
      key: 'purchase_count',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) =>
        (this.purchaseStats.get(a.id)?.purchaseCount || 0) - (this.purchaseStats.get(b.id)?.purchaseCount || 0),
      format: (item) => (this.purchaseStats.get(item.id)?.purchaseCount || 0).toString()
    },
    <RowSettings>{
      name: '採購總數量',
      key: 'historical_purchase_quantity',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) =>
        (this.purchaseStats.get(a.id)?.totalQuantity || 0) - (this.purchaseStats.get(b.id)?.totalQuantity || 0),
      format: (item) => (this.purchaseStats.get(item.id)?.totalQuantity || 0).toString()
    },
    <RowSettings>{
      name: '創建時間',
      key: 'created_at',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.created_at?.localeCompare(b.created_at) ?? false,
    },
    <RowSettings>{
      name: '狀態',
      key: 'status',
      show: false,
      custom: true,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: Product, b: Product) => a.status.localeCompare(b.status) ?? false,
    },
    <RowSettings>{
      name: '鋪店率',
      key: 'store_coverage',
      show: false,
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: ProductWithInventory, b: ProductWithInventory) => {
        const coverageA = (a.locations_with_inventory || 0) / (this.locations?.length || 1);
        const coverageB = (b.locations_with_inventory || 0) / (this.locations?.length || 1);
        return coverageA - coverageB;
      },
      format: (item: ProductWithInventory) => {
        const coverage = (item.locations_with_inventory || 0) / (this.locations?.length || 1);
        return `${(coverage * 100).toFixed(1)}%`;
      }
    },
    <RowSettings>{
  name: '未銷售天數',
  key: 'days_since_last_sale',
  show: false,
  sortOrder: null,
  sortDirections: ['ascend', 'descend', null],
sortFn: (a: any, b: any) => {
  const daysA = a.days_since_last_sale !== null && a.days_since_last_sale !== undefined ? a.days_since_last_sale : Infinity;
  const daysB = b.days_since_last_sale !== null && b.days_since_last_sale !== undefined ? b.days_since_last_sale : Infinity;
  return daysA - daysB;
},
format: (item: any) => {
  return item.days_since_last_sale !== null && item.days_since_last_sale !== undefined 
    ? item.days_since_last_sale.toString() 
    : '從未銷售';
}
},
  ];
  Product_rows = this.Product_default_rows;

  // 首先在组件中添加一个 Map 来存储每个商品的每日销售数据
  salesDetails = new Map<
    number,
    Array<{
      sale_date: string;
      quantity: number;
    }>
  >();

  // 添加日期範圍屬性
  purchaseDateRange: [Date, Date] | null = null;

  loadingInventoryMap = new Map<string, boolean>();
  public popoverVisibilityMap = new Map<number, boolean>();
  private salesLoadingMap = new Map<number, boolean>();

  private salesAverages = new Map<number, number>();
    // 添加一个类成员变量来存储相关产品映射
  private relatedProductMap = new Map<number, {relatedId: number, coefficient: number}>();

  // 添加新的 Map 来存储统计数据
  private purchaseStats = new Map<number, {
    totalQuantity: number,
    purchaseCount: number
  }>();

  // 添加新的屬性
  minInventory: number = 0;
  maxInventory: number = 1000;  // 設置一個默認最大值
  inventoryRange: [number, number] = [0, 1000];

  // 添加毛利率相關屬性
  minMargin: number = 0;
  maxMargin: number = 1;
  marginRange: [number, number] = [0, 1];

  // 添加相關屬性
  minAverageDistribution: number = 0;
  maxAverageDistribution: number = 1000;
  averageDistributionRange: [number, number] = [0, 1000];

  // 添加相關屬性
  minAverageSales: number = 0;
  maxAverageSales: number = 1000;
  averageSalesRange: [number, number] = [0, 1000];

  inventoryMarks = {};
  averageDistributionMarks = {};
  averageSalesMarks = {};

  showMoreFilters = false;

  // 添加到期日期範圍屬性
  expiryDateRange: [Date, Date] | null = null;

  isFirstLevelLocked: boolean = false;

  latestSyncTime: Date | null = null;

  selectedLocationSales: LocationDailySales[] = [];

  private locationPopoverVisibleMap = new Map<string, boolean>();

  getLocationPopoverVisible(productId: number, locationId: number): boolean {
    return this.locationPopoverVisibleMap.get(`${productId}-${locationId}`) || false;
  }

  async onLocationPopoverVisibleChange(visible: boolean, productId: number, locationId: number) {
    const key = `${productId}-${locationId}`;
    this.locationPopoverVisibleMap.set(key, visible);
    
    if (visible) {
      await this.handleLocationSalesClick(productId, locationId);
    }
  }
  calculateLocationAverage(productId: number, locationId: number): number {
  const key = `${productId}-${locationId}`;
  
  // 如果已经计算过，直接返回缓存的值
  if (this.locationAveragesMap.has(key)) {
    return this.locationAveragesMap.get(key)!;
  }

  const sales = this.salesLocationDetailsMap.get(productId) || [];
  const locationSales = sales.filter(sale => sale.location_id === locationId);
  
  // 过滤出非零销量的记录
  const nonZeroSales = locationSales.filter(sale => sale.quantity > 0);
  
  if (nonZeroSales.length === 0) {
    this.locationAveragesMap.set(key, 0);
    return 0;
  }

  // 计算平均值：总销量除以非零销量的天数
  const totalQuantity = nonZeroSales.reduce((sum, sale) => sum + sale.quantity, 0);
  const average = totalQuantity / nonZeroSales.length;
  
  // 缓存计算结果
  this.locationAveragesMap.set(key, average);
  
  return average;
}

  getLocationIdFromKey(key: string): number {
    const match = key.match(/location_sales_(\d+)/);
    return match ? parseInt(match[1]) : 0;
  }

  async handleLocationSalesClick(productId: number, locationId: number): Promise<void> {
    try {
      this.isLocationOrderLoading = true; // 开始加载时设置为true
      
      // 如果数据不存在，先获取
      if (!this.salesLocationDetailsMap.has(productId)) {
        await this.getOrderItemsByProductIdWithLocation(productId);
      }

      const allSales = this.salesLocationDetailsMap.get(productId) || [];
      
      // 筛选特定门店的销售数据
      this.selectedLocationSales = allSales
        .filter(sale => sale.location_id === locationId)
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        this.calculateLocationAverage(productId, locationId);
      
    } catch (error) {
      console.error('獲取門店銷售數據失敗:', error);
      this.message.error('獲取門店銷售數據失敗');
    } finally {
      this.isLocationOrderLoading = false; // 无论成功还是失败，都将loading状态设置为false
    }
  }

  constructor(
    private state: StateService,
    private router: Router,
    private webService: WebService,
    private message: NzMessageService,
    private modalService: NzModalService,
    private cacheService: CacheService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    public permission: PermissionService
  ) {
    this.permission.check(this.permission.isProductSectionAllowed);
    this.disableForm = this.fb.group({
      id: [''],
      status: ['deleted'],
    });
  }

ngOnInit(): void {
  this.loadUsers();
  this.loadLocations();
  // 先加载产品数据
  this.loadProducts();
  
  this.getLatestSyncTime();

}
async loadAverageProductSales(): Promise<void> {
  try {
    const response = await this.webService.getAverageProductSales();
    console.log('845response', response);
    
    if (response) {
      // 將平均銷售數據添加到產品列表中
      this.ProductList = this.ProductList.map(product => {
        const salesData = response.find(item => item.product_id === product.id);
        return {
          ...product,
          average_sales: salesData?.average_sales || 0
        };
      });
    }
    
  } catch (error) {  
    console.error('獲取產品平均銷售數據失敗:', error);
    this.message.error('獲取產品平均銷售數據失敗');
  }
}

getAverageSalesValue(product: any): string {
  const value = product.average_sales;
  return value !== undefined ? value.toFixed(1) : '0.0';
}


  async loadUsers() {
    try {
      // 1. 先獲取當前用戶信息
      const user = localStorage.getItem('user');
      if (!user) {
        throw new Error('未找到用戶信息');
      }
      // 2. 解析並設置當前用戶數據
      const userInfo = JSON.parse(user);
      this.roleId = userInfo.roleId;
      this.userId = userInfo.id;
      this.user = userInfo;
      
      // 3. 獲取所有用戶數據
      const users = await this.cacheService.allUsers();
      const currentUserDetails = users.find(u => u.id === this.userId);
      console.log('currentUserDetails', currentUserDetails);
      
      // 使用類型斷言訪問可能存在的 supplier_id
      const supplierId = (currentUserDetails as any)?.supplier_id || userInfo.supplier_id;
      
      if (supplierId) {
        console.log('當前用戶的 supplier_id:', supplierId);
        
        // 調用 findProductsBySupplier 獲取供應商相關產品
        try {
          const productsResponse = await this.webService.findProductsBySupplier(supplierId);
          console.log('productsResponse', productsResponse);
          
          // 根據實際響應格式調整數據處理邏輯
          if (productsResponse && productsResponse.data) {
            // 保存供應商相關產品 ID
            this.supplierProductIds = productsResponse.data;
            console.log('供應商相關產品 IDs:', this.supplierProductIds);
            
          }
        } catch (error) {
          console.error('獲取供應商相關產品失敗:', error);
        }
      } else {
        console.log('當前用戶沒有 supplier_id');
      }
      
      if (currentUserDetails?.department_details) {
        this.user = {
          ...this.user,
          department_details: currentUserDetails.department_details
        };
        // 不在這裡立即設置過濾條件
        this.isFirstLevelLocked = currentUserDetails.department_details.length > 0;
      }
    } catch (error) {
      console.error('加載用戶失敗:', error);
      this.message.error('加載用戶數據失敗');
    }
  }

  async loadLocations(): Promise<void> {
    try {
      const allLocations = await this.cacheService.allLocations();
      this.locations = allLocations.filter(location => 
        location.status === 'active' && location.type !== 'warehouse'
      );
      
      const locationColumns = this.locations.map(location => 
        <RowSettings>{
          name: `${location.name_zh}銷售`,
          key: `location_sales_${location.id}`,
          show: false,
          sortOrder: null,
          sortDirections: ['ascend', 'descend', null],
          sortFn: (a: Product, b: Product) => {
            const salesA = (a as any)[`location_sales_${location.id}`] || 0;
            const salesB = (b as any)[`location_sales_${location.id}`] || 0;
            return salesA - salesB;
          },
          format: (product: Product) => {
            const sales = (product as any)[`location_sales_${location.id}`];
            if (sales === undefined || sales === null) {
              return null; // 返回null时显示放大镜图标
            }
            return sales.toString();
          },
          width: '100px',
          custom: true // 添加custom标记以便在模板中特殊处理
        }
      );

      // 将新列添加到Product_default_rows的末尾
      this.Product_default_rows = [
        ...this.Product_default_rows,
        ...locationColumns
      ];

      // 更新Product_rows
      this.Product_rows = this.Product_default_rows;
      
    } catch (error) {
      this.message.error('加載倉庫數據失敗');
    }
  }



  // 添加一个新函数来构建相关产品映射
  private buildRelatedProductMap(): void {
    this.relatedProductMap.clear();
    
    this.ProductList.forEach(product => {
      if (product.related_product_id && product.related_product_coefficient) {
        this.relatedProductMap.set(product.id, {
          relatedId: product.related_product_id,
          coefficient: product.related_product_coefficient
        });
      }
    });
    
    console.log('相关产品映射已构建，共有', this.relatedProductMap.size, '个产品有关联关系');
  }

  async loadProducts(): Promise<void> {
    try {
      this.isSpinning = true;
      // 获取产品数据
      const products = await this.cacheService.allProducts();
      
      this.ProductList = products.filter((product: any) => product.status !== 'deleted');
      
      if (!this.permission.isViewAllProductsAndSuppliersAllowed) {
        // 檢查用戶是否有 supplier_id 並且有相關產品
        if (this.supplierProductIds && this.supplierProductIds.length > 0) {
          // 如果是供應商用戶，顯示與該供應商相關的產品
          this.ProductList = this.ProductList.filter(
            product => this.supplierProductIds.includes(product.id)
          );
          console.log('1089this.ProductList', this.ProductList);
        } else {
          // 如果不是供應商用戶，使用原有的買家名稱過濾邏輯
          this.ProductList = this.ProductList.filter(
            product => product.buyer_name?.toString()?.toLowerCase() === this.user?.username?.toLowerCase()
          );
        }
      }
      
      // 构建相关产品映射
      this.buildRelatedProductMap();
      
      this.ProductListFiltered = this.filterProducts();
      this.search();
      this.isSpinning = false;
      
      // 等待所有數據加載完成
      await Promise.all([
        this.loadAverageProductSales(),
        this.fetchSimpleInventory(),
        this.loadProductSalesLastSevenDays(),
        this.getWarehouseTransfersData(),
        this.getLatestPurchases(),
        this.loadPurchaseOrderProducts(),
        this.loadProductsLastSaleDays()
      ]);
      
      // 處理數據
      if (this.isFirstLevelLocked && this.user?.department_details?.length > 0) {
        this.searchParam.first_level_name = [...this.user.department_details];
      }
      
      this.setFilterOptions();
      this.updateMarks();
      
    } catch (error) {
      console.error('加載產品數據失敗:', error);
      this.message.error('加載產品數據失敗');
    }
  }

  async loadProductsNew(): Promise<void> {
    try {
      
      // this.isSpinning = true;
      // 等待獲取產品數據  await this.webService.getProductList()
          console.time('getProductList');
    
    const products = await this.webService.getProductList();
    
    // 结束计时并输出耗时
    console.timeEnd('getProductList');
      console.log('794products', products);
      
      console.time('getAllProductsSimple');
      const productsSimple = await this.webService.getAllProductsSimple();
      console.timeEnd('getAllProductsSimple');
      console.log('794productsSimple', productsSimple);




      // this.ProductList = products.filter((product: any) => product.status !== 'deleted');
      
      // if (!this.permission.isViewAllProductsAndSuppliersAllowed) {
      //   this.ProductList = this.ProductList.filter(
      //     product => product.buyer_name?.toString()?.toLowerCase() === this.user?.username?.toLowerCase()
      //   );
      // }

      // this.isSpinning = false;

      // 等待所有數據加載完成
      // await Promise.all([
      //   this.fetchSimpleInventory(),
      //   this.loadProductSalesLastSevenDays(),
      //   this.getWarehouseTransfersData(),
      //   this.getLatestPurchases(),
      //   this.loadPurchaseOrderProducts()
      // ]);

      // // 處理數據
      // if (this.isFirstLevelLocked && this.user?.department_details?.length > 0) {
      //   this.searchParam.first_level_name = [...this.user.department_details];
      // }

      // this.ProductListFiltered = this.filterProducts();
      // this.search();
      // this.setFilterOptions();
      // this.updateMarks();
    } catch (error) {
      console.error('加載產品數據失敗:', error);
      this.message.error('加載產品數據失敗');
    } finally {
      
    }
  }

  async getWarehouseTransfersData(): Promise<void> {
    this.isTransferDataLoading = true;
    try {
      const endDate = new Date();
      endDate.setHours(23, 59, 59, 999); // 设置为当天的最后一毫秒

      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      startDate.setHours(0, 0, 0, 0); // 设置为7天前的第一毫秒
      const formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(startDate.getDate()).padStart(2, '0')}T00:00:00+08:00`;
      const formattedEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(
        endDate.getDate()
      ).padStart(2, '0')}T23:59:59+08:00`;

      // 使用新的API获取数据
      const res = await this.webService.getBetweenWarehouseTransferData(formattedStartDate, formattedEndDate);

      if (res?.body) {
        // 获取日期数组（最近7天加今天）
        const dates: string[] = [];
        const currentDate = new Date(startDate);

        // 使用 do...while 循环确保包含结束日期
        do {
          // 使用本地时间格式化日期
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, '0');
          const day = String(currentDate.getDate()).padStart(2, '0');
          dates.push(`${year}-${month}-${day}`);

          currentDate.setDate(currentDate.getDate() + 1);
        } while (currentDate <= endDate);
        // 从响应数据中提取商品信息
        const transferProducts: TransferProduct[] = [];
        res.body.forEach(transfer => {
          if (transfer.warehouse_transfer_products && Array.isArray(transfer.warehouse_transfer_products)) {
            transfer.warehouse_transfer_products.forEach(product => {
              transferProducts.push({
                id: product.id,
                name: product.name,
                shopify_id: product.shopify_id,
                actual_transfer_quantity: product.actual_transfer_quantity,
              });
            });
          }
        });

        // 创建转移详情Map
        this.transferDetails = new Map();

        // 处理每日转移数据
        res.body.forEach(transfer => {
          if (transfer.warehouse_transfer_products && Array.isArray(transfer.warehouse_transfer_products)) {
            transfer.warehouse_transfer_products.forEach(product => {
              const shopifyId = product.shopify_id;

              if (!this.transferDetails.has(shopifyId)) {
                this.transferDetails.set(
                  shopifyId,
                  dates.map(date => ({
                    transfer_date: date,
                    actual_transfer_quantity: 0,
                  }))
                );
              }

              const transfers = this.transferDetails.get(shopifyId)!;
              const dateIndex = transfers.findIndex(t => t.transfer_date === transfer.transfer_date.split('T')[0]);
              if (dateIndex !== -1) {
                transfers[dateIndex].actual_transfer_quantity += product.actual_transfer_quantity;
              }
            });
          }
        });

        // 确保所有商品都有完的8天数据
        this.ProductList.forEach(product => {
          if (!this.transferDetails.has(product.shopify_id)) {
            this.transferDetails.set(
              product.shopify_id,
              dates.map(date => ({
                transfer_date: date,
                actual_transfer_quantity: 0,
              }))
            );
          }
        });

        // 对每个商品的转移记录按日期升序排序
        this.transferDetails.forEach(transfers => {
          transfers.sort((a, b) => new Date(a.transfer_date).getTime() - new Date(b.transfer_date).getTime());
        });

        // 计算每个shopify_id的出货天数
        const transferDaysByShopifyId = res.body.reduce((acc, transfer) => {
          transfer.warehouse_transfer_products?.forEach(product => {
            if (product.actual_transfer_quantity > 0) {
              if (!acc[product.shopify_id]) {
                acc[product.shopify_id] = new Set();
              }
              const dateOnly = transfer.transfer_date.split('T')[0];
              acc[product.shopify_id].add(dateOnly);
            }
          });
          return acc;
        }, {} as { [key: string]: Set<string> });

        // 计算总转移数量
        const quantityByShopifyId = transferProducts.reduce((acc, product) => {
          acc[product.shopify_id] = (acc[product.shopify_id] || 0) + product.actual_transfer_quantity;
          return acc;
        }, {} as { [key: string]: number });

        // 更新商品列表
        this.ProductList = this.ProductList.map(product => {
          const totalQuantity = quantityByShopifyId[product.shopify_id] || 0;
          const transferDays = transferDaysByShopifyId[product.shopify_id]?.size || 0;
          const averageQuantity = transferDays > 0 ? totalQuantity / transferDays : 0;

          return {
            ...product,
            total_actual_transfer_quantity: totalQuantity,
            transfer_days: transferDays,
            average_daily_transfer: Number(averageQuantity.toFixed(2)),
          };
        });

        this.ProductListFiltered = Array.from(this.ProductList);
        this.updateAverageDistributionRange();
      } else {
        
        this.message.warning('無出貨單數據');
      }
    } catch (error) {
      console.error('Error:', error);
      this.message.error('獲取數據失敗');
    } finally {
      this.isTransferDataLoading = false;
    }
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.ProductList.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly Product[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  viewProductDetail(id?): void {
    if (id) {
      this.router.navigate(['products/detail', id]);
    } else if (this.setOfCheckedId.size === 1) {
      const id = Array.from(this.setOfCheckedId)[0];
      this.router.navigate(['products/detail', id]);
    }
  }

  editProduct(id?): void {
    if (id) {
      this.router.navigate(['products/update', id]);
    } else if (this.setOfCheckedId.size === 1) {
      const id = Array.from(this.setOfCheckedId)[0];
      this.router.navigate(['products/update', id]);
    }
  }

  confirmDelete(id?: number): void {
    let productToDelete: Product | undefined;

    if (id) {
      productToDelete = this.ProductList.find(product => product.id === id);
    } else if (this.setOfCheckedId.size === 1) {
      const selectedId = Array.from(this.setOfCheckedId)[0];
      productToDelete = this.ProductList.find(product => product.id === selectedId);
    }

    if (!productToDelete) {
      this.message.error('未选择要删除的产品。');
      return;
    }

    this.modalService.confirm({
      nzTitle: '確定刪除',
      nzContent: `確定要刪除產品 <b style="color: red;">${productToDelete.name}</b> 嗎？`,
      nzOkText: '删除',
      nzOnOk: () => {
        this.webService.deleteProduct(productToDelete?.id!, this.state.user.id).then(res => {
          this.message.success('產品已刪除');
          this.reload();
        });
      },
      nzCancelText: '取消',
      nzOnCancel: () => {
        this.message.info('已取消刪除操作。');
      },
    });
  }

  confirmBatchDelete(): void {
    this.isBatchDeleteModalVisible = true;
    if (this.setOfCheckedId.size > 1) {
      this.isBatchDeleteModalVisible = true;
      const ids = Array.from(this.setOfCheckedId);
      console.log('Selected IDs:', ids);
    } else {
      this.message.error('請選擇產品');
    }
  }

  disableProduct(product: any) {
    this.disableForm.patchValue({
      status: product.status,
      id: product.id,
      updated_by: this.userId,
    });
  }

  handleCancel(): void {
    this.isDeleteModalVisible = false;
    this.isBatchDeleteModalVisible = false;
  }

  handleOk(): void {
    this.submitDisableForm();
    this.isDeleteModalVisible = false;
  }

  handleBatchOk(): void {
    this.submitBatchDisableForm();
    this.isBatchDeleteModalVisible = false;
  }

  submitDisableForm() {
    const updatedUser = this.disableForm.value;
    const updateUserDto = {
      id: updatedUser.id,
      status: 'deleted',
      updated_by: this.userId,
    };

    this.webService
      .editProduct(updatedUser.id, updateUserDto)
      .then(response => {
        this.message.success('Delete successfully');
        this.reload();
      })
      .catch(error => {
        this.message.error('Failed to change user status');
      });
  }

  submitBatchDisableForm(): void {
    const userIds = Array.from(this.setOfCheckedId);
    const userDtos = userIds.map(id => this.ProductListFiltered.find(product => product.id === id));

    Promise.all(
      userDtos.map(userDto => {
        if (userDto) {
          this.disableProduct(userDto);
          return this.submitDisableForm();
        }
      })
    )
      .then(() => {
        this.message.success('Delete successfully');
        this.reload();
      })
      .catch(error => {
        this.message.error('Failed to change user statuses');
      });
  }

  changeSignalCheck(e: boolean, item: RowSettings): void {
    item.show = e;
  }

  dropTableConfig(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.Product_rows, event.previousIndex, event.currentIndex);
  }

  setFilterOptions() {
    this.productNames.clear();
    this.statuses.clear();
    this.barcodes.clear();
    this.brands.clear();
    this.countries.clear();
    this.brandsList.clear();
    this.first_level.clear();
    this.second_level.clear();
    this.third_level.clear();
    this.buyer_set.clear();

    this.ProductListFiltered.forEach((product: Product) => {
      const productNameMatch =
        this.searchParam.product_name === null ||
        this.searchParam.product_name === '' ||
        (product.name && product.name.toLowerCase().includes(this.searchParam.product_name.toLowerCase()));

      const statusMatch =
        this.searchParam.status === null ||
        this.searchParam.status === '' ||
        product.status === this.searchParam.status;

      const barcodeMatch =
        this.searchParam.barcode === null ||
        this.searchParam.barcode === '' ||
        (product.barcode && product.barcode.includes(this.searchParam.barcode));

      const countryMatch =
        this.searchParam.country === null ||
        this.searchParam.country.length === 0 ||
        (product.country &&
          ((product.country.name_zh && this.searchParam.country.includes(product.country.name_zh)) ||
            (product.country.name_zh && this.searchParam.country.includes(product.country.name_zh))));

      const brandListMatch =
        this.searchParam.brand_list === null ||
        this.searchParam.brand_list.length === 0 ||
        (product.brand && this.searchParam.brand_list.includes(product.brand.name));

      const firstLevelNameMatch =
        this.searchParam.first_level_name === null ||
        this.searchParam.first_level_name.length === 0 ||
        (product.category && this.searchParam.first_level_name.includes(product.category.first_level_name));

      const secondLevelNameMatch =
        this.searchParam.second_level_name === null ||
        this.searchParam.second_level_name.length === 0 ||
        (product.category && this.searchParam.second_level_name.includes(product.category.second_level_name));

      const thirdLevelNameMatch =
        this.searchParam.third_level_name === null ||
        this.searchParam.third_level_name.length === 0 ||
        (product.category && this.searchParam.third_level_name.includes(product.category.third_level_name));
      //console.log('this.searchParam.buyer_id:', this.searchParam.buyer_id);
      const buyerMatch =
        this.searchParam.buyer_id === null ||
        this.searchParam.buyer_id === '' ||
        (product.buyer_id && product.buyer_id.toString().includes(this.searchParam.buyer_id));

      const allMatch =
        productNameMatch &&
        statusMatch &&
        barcodeMatch &&
        countryMatch &&
        brandListMatch &&
        firstLevelNameMatch &&
        secondLevelNameMatch &&
        thirdLevelNameMatch &&
        buyerMatch;

      if (allMatch) {
        if (product.name) this.productNames.add(product.name);
        if (product.status) this.statuses.add(product.status);
        if (product.barcode) this.barcodes.add(product.barcode);
        if (product.buyer_id) this.buyer_set.add(product.buyer_id.toString());
      }

      if (!(productNameMatch && statusMatch && barcodeMatch && buyerMatch)) return;

      if (brandListMatch && firstLevelNameMatch && secondLevelNameMatch && thirdLevelNameMatch) {
        if (product.country) {
          // 根據語言環境選擇顯示中文或英文名稱
          const countryName = product.country.name_zh || product.country.name_en;
          this.countries.add(countryName);
        }
      }

      if (countryMatch && firstLevelNameMatch && secondLevelNameMatch && thirdLevelNameMatch) {
        if (product.brand) {
          this.brandsList.add(product.brand.name);
        }
      }

      if (countryMatch && brandListMatch && secondLevelNameMatch && thirdLevelNameMatch) {
        const firstLevelName = product.category?.first_level_name;
        if (firstLevelName) this.first_level.add(firstLevelName);
      }

      if (countryMatch && brandListMatch && firstLevelNameMatch && thirdLevelNameMatch) {
        const secondLevelName = product.category?.second_level_name;
        if (secondLevelName) this.second_level.add(secondLevelName);
      }

      if (countryMatch && brandListMatch && firstLevelNameMatch && secondLevelNameMatch) {
        const thirdLevelName = product.category?.third_level_name;
        if (thirdLevelName) this.third_level.add(thirdLevelName);
      }
    });

    // 使用新的屬性結構重新設置集合
    this.productNames = new Set(this.ProductList.filter(p => p.name).map(p => p.name));
    this.statuses = new Set(this.ProductList.filter(p => p.status).map(p => p.status));
    this.barcodes = new Set(this.ProductList.filter(p => p.barcode).map(p => p.barcode));
    this.brands = new Set(this.ProductList.filter(p => p.brand).map(p => p.brand!.name));

    this.countries = new Set(
      this.ProductList.filter(p => p.country?.name_zh || p.country?.name_en || p.country_name)
        .map(p => p.country?.name_zh || p.country?.name_en || p.country_name || '')
        .filter(countryName => countryName !== '')
    );

    this.brandsList = new Set(this.ProductList.filter(p => p.brand?.name).map(p => p.brand!.name));
    this.first_level = new Set(
      this.ProductList.filter(p => p.category?.first_level_name).map(p => p.category!.first_level_name)
    );
    this.second_level = new Set(
      this.ProductList.filter(p => p.category?.second_level_name).map(p => p.category!.second_level_name)
    );
    this.third_level = new Set(
      this.ProductList.filter(p => p.category?.third_level_name).map(p => p.category!.third_level_name)
    );

    this.buyer_set = new Set(this.ProductList.filter(p => p.buyer_id?.toString()).map(p => p.buyer_id!.toString()));
    this.supplierFilterOptions = Array.from(
      new Set(
        this.ProductList.map(product => {
          const zhName = product.latest_purchase?.supplier_name_zh;
          const enName = product.latest_purchase?.supplier_name_en;
          
          // 如果中英文名稱都存在，則顯示為 "中文名稱/英文名稱" 的格式
          if (zhName && enName) {
            return `${zhName}/${enName}`;
          }
          // 如果只有中文名稱，則只顯示中文名稱
          else if (zhName) {
            return zhName;
          }
          // 如果只有英文名稱，則只顯示英文名稱
          else if (enName) {
            return enName;
          }
          // 如果都沒有，則顯示 '-'
          return '';
        }).filter(
          (name): name is string => typeof name === 'string' && name.trim() !== ''
        )
      )
    ).sort();

    // console.log('供应商选项:', this.supplierFilterOptions);
    // 轉換為數組
    this.productNameFilterOptions = Array.from(this.productNames);
    this.statusFilterOptions = Array.from(this.statuses);
    this.barcodesFilterOptions = Array.from(this.barcodes);
    this.brandFilterOptions = Array.from(this.brands);
    this.countryFilterOptions = Array.from(this.countries);
    this.brandListFilterOptions = Array.from(this.brandsList);
    this.firstLevelFilterOptions = Array.from(this.first_level);
    this.secondLevelFilterOptions = Array.from(this.second_level);
    this.thirdLevelFilterOptions = Array.from(this.third_level);
    this.buyerFilterOptions = Array.from(this.buyer_set);

    // 根據選擇的一級分類過濾二級分類
    this.secondLevelFilterOptions = Array.from(
      new Set(
        this.ProductList
          .filter(p => {
            // 如果沒有選擇一級分類，顯示所有二級分類
            if (!this.searchParam.first_level_name?.length) {
              return true;
            }
            // 只顯示選中一級分類下的二級分類
            return p.category && this.searchParam.first_level_name.includes(p.category.first_level_name);
          })
          .map(p => p.category?.second_level_name)
          .filter((name): name is string => !!name)
      )
    );

    // 根據選擇的二級分類過濾三級分類
    this.thirdLevelFilterOptions = Array.from(
      new Set(
        this.ProductList
          .filter(p => {
            // 如果沒有選擇二級分類，但選擇了一級分類
            if (!this.searchParam.second_level_name?.length && this.searchParam.first_level_name?.length) {
              return p.category && this.searchParam.first_level_name.includes(p.category.first_level_name);
            }
            // 如果選擇了二級分類
            if (this.searchParam.second_level_name?.length) {
              return p.category && this.searchParam.second_level_name.includes(p.category.second_level_name);
            }
            // 如果都沒有選擇，顯示所有三級分類
            return true;
          })
          .map(p => p.category?.third_level_name)
          .filter((name): name is string => !!name)
      )
    );
  }

  isNotEmpty(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
  }

  

  async loadPurchaseOrderProducts(): Promise<void> {
    try {
      const response = await this.webService.getAllPurchaseOrderProducts();
      if (response?.body) {
        // console.log('採購訂單產品數據:', response.body);

        // 用于去重的Set，存储 product_id-purchase_order_id-quantity 组合
        const uniqueOrders = new Set<string>();

        // 清空现有统计数据
        this.purchaseStats.clear();

        response.body.forEach(item => {
          const { product_id, purchase_order_id, quantity } = item;

          // 创建唯一标识
          const uniqueKey = `${product_id}-${purchase_order_id}-${quantity}`;

          // 如果这个组合已经存在，跳过
          if (uniqueOrders.has(uniqueKey)) {
            return;
          }
          uniqueOrders.add(uniqueKey);

          // 获取或初始化该产品的统计数据
          const stats = this.purchaseStats.get(product_id) || {
            totalQuantity: 0,
            purchaseCount: 0
          };

          // 更新统计数据
          stats.totalQuantity += Number(quantity) || 0;

          // 检查是否是该产品在此采购订单中的第一次记录
          const orderKey = `${product_id}-${purchase_order_id}`;
          if (!Array.from(uniqueOrders).some(key =>
            key.startsWith(orderKey) && key !== uniqueKey)) {
            stats.purchaseCount += 1;
          }

          this.purchaseStats.set(product_id, stats);
        });


        // console.log('採購統計數據:', Object.fromEntries(this.purchaseStats));
      }
    } catch (error) {
      console.error('加載採購訂單產品失敗:', error);
      this.message.error('加載採購訂單產品失敗');
    }
  }

  shouldShowPopover(product: Product): boolean {
    return (
      Boolean(this.inventoryPopoverMap.get(product.shopify_id)) && this.hasNonZeroInventory(product.inventory_details)
    );
  }

  async handlePopoverVisibility(shopifyId: string, visible: boolean) {
    if (visible) {
      // 如果是要显示，先获取数据
      await this.getInventoryAmount(shopifyId);
      // 获取数据后，根据是否有非零库存来决定是否显示
      const product = this.ProductList.find(p => p.shopify_id === shopifyId);
      if (product && this.hasNonZeroInventory(product.inventory_details)) {
        this.inventoryPopoverMap.set(shopifyId, true);
      } else {
        this.inventoryPopoverMap.set(shopifyId, false);
      }
    } else {
      // 如果是要关闭，直接关闭
      this.inventoryPopoverMap.set(shopifyId, false);
    }
  }

  async getInventoryAmount(shopifyId: string) {
    if (!shopifyId) {
      this.message.warning('無效的 Shopify ID');
      return;
    }

    try {
      // 设置当前商品的加载状态
      this.loadingInventoryMap.set(shopifyId, true);

      const response = await this.webService.getProductsInventories([shopifyId]);

      if (response?.data && response.data.length > 0) {
        const item = response.data[0];
        const total = item.inventoryQuantities.reduce((sum, loc) => sum + (loc.available || 0), 0);

        // 更新商品数据
        const updateProduct = (list: Product[]) =>
          list.map(product => {
            if (product.shopify_id === shopifyId) {
              return {
                ...product,
                inventory_quantity: total,
                inventory_details: item.inventoryQuantities,
              };
            }
            return product;
          });

        this.ProductList = updateProduct(this.ProductList);
        this.ProductListFiltered = updateProduct(this.ProductListFiltered);

        this.inventoryPopoverMap.set(shopifyId, true);
      } else {
        this.message.warning('未獲取到庫存數據');
      }
    } catch (error) {
      console.error('加載庫存數據時發生錯誤:', error);
      this.message.error('加載庫存數據失敗');
    } finally {
      this.loadingInventoryMap.set(shopifyId, false);
    }
  }
  async getLocationNameById(locationId: string): Promise<string> {
    try {
      // 如果locations為空，則獲取數據
      if (this.locations.length === 0) {
        const response = await this.webService.getAllLocations();
        if (response?.body) {
          this.locations = response.body;
        }
      }

      const location = this.locations.find(loc => loc.shopify_id?.toString() === locationId);
      return location ? location.name_zh : locationId;
    } catch (error) {
      console.error('Error fetching locations:', error);
      return locationId;
    }
  }
  filterValidLocations(details: any[]): any[] {
    if (!details || !this.locations) return [];

    return details.filter(detail =>
      this.locations.some(location => location.shopify_id?.toString() === detail.locationId?.toString())
    );
  }
  getFilteredProductNames(input: string): string[] {
    if (!input) return this.productNameFilterOptions.slice(0, 100);

    return this.productNameFilterOptions.filter(name => name.toLowerCase().includes(input.toLowerCase())).slice(0, 100);
  }
  getFilteredBarcode(input: string): string[] {
    if (!input) return this.barcodesFilterOptions.slice(0, 100);

    input = input.toLowerCase();
    return this.barcodesFilterOptions
      .filter(name => name.toLowerCase().startsWith(input))
      .sort((a, b) => a.localeCompare(b))
      .slice(0, 100);
  }

  // 修改模板中使用的方法
  getLocationName(locationId: string): string {
    const location = this.locations.find(loc => loc.shopify_id?.toString() === locationId?.toString());
    return location ? location.name_zh : ''; // 如果找不到对应位置，返回空字符串
  }

  async loadProductSalesLastSevenDays() {
    this.isSalesDataLoading = true;
    try {
      // 计算日期范围
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 6); // 改为-6，因为要包含今天在内的7天

      const formattedStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}T00:00:00+08:00`;
      const formattedEndDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}T23:59:59+08:00`;

      // 获取日期数组（不包括今天）
      const dates: string[] = [];
      for (let i = 6; i >= 1; i--) { // 改为从6到1，排除今天
        const date = new Date();
        date.setDate(date.getDate() - i);
        dates.push(date.toISOString().split('T')[0]);
      }

      const response = await this.webService.getOrderItemsBetweenDates(formattedStartDate, formattedEndDate);
      
      if (response?.body) {
        const dailySalesMap = new Map<number, Map<string, number>>();
        const lastWeekSalesMap = new Map<number, number>();

        const today = new Date().toISOString().split('T')[0];

        // 初始化每个产品的每日销售数据为0
        response.body.forEach((orderItem: OrderItem) => {
          if (!orderItem.product?.id) return;
          const productId = orderItem.product.id;

          if (!dailySalesMap.has(productId)) {
            dailySalesMap.set(productId, new Map());
            // 初始化所有日期的销售量为0
            dates.forEach(date => {
              dailySalesMap.get(productId)!.set(date, 0);
            });
          }
        });

        // 处理销售数据
        response.body.forEach((orderItem: OrderItem) => {
          if (!orderItem.product?.id) return;
          const productId = orderItem.product.id;
          const orderDate = new Date(orderItem.order.order_time).toISOString().split('T')[0];
          const quantity = Number(orderItem.quantity);

          // 只处理不是今天的数据
          if (orderDate !== today && dates.includes(orderDate)) {
            const productDailySales = dailySalesMap.get(productId)!;
            const currentDayTotal = (productDailySales.get(orderDate) || 0) + quantity;
            productDailySales.set(orderDate, currentDayTotal);

            // 更新上周销量（不包括今天）
            lastWeekSalesMap.set(productId, (lastWeekSalesMap.get(productId) || 0) + quantity);
          }
        });

        // 更新 salesDetails，包含相关产品的销售数据
        this.salesDetails.clear();
        
        // 先处理基本的销售数据
        dailySalesMap.forEach((dailySales, productId) => {
          this.salesDetails.set(
            productId,
            dates.map(date => ({
              sale_date: date,
              quantity: dailySales.get(date) || 0,
            }))
          );
        });
        
        // 然后处理有相关产品的情况
        this.ProductList.forEach(product => {
          if (product.related_product_id && product.related_product_coefficient) {
            const ownSalesDetails = this.salesDetails.get(product.id) || [];
            const relatedSalesDetails = this.salesDetails.get(product.related_product_id) || [];
            
            // 合并自身和相关产品的销售数据
            const combinedSalesDetails = dates.map(date => {
              const ownSale = ownSalesDetails.find(s => s.sale_date === date)?.quantity || 0;
              const relatedSale = relatedSalesDetails.find(s => s.sale_date === date)?.quantity || 0;
              
              return {
                sale_date: date,
                quantity: ownSale + (relatedSale * product.related_product_coefficient)
              };
            });
            
            // 更新销售详情
            this.salesDetails.set(product.id, combinedSalesDetails);
          }
        });

        // 更新 ProductList，包含相关产品的销量
        this.ProductList = this.ProductList.map(product => {
          // 获取产品自身的销量
          let ownSales = lastWeekSalesMap.get(product.id) || 0;
          
          // 如果产品有相关产品，加上相关产品的销量乘以系数
          if (product.related_product_id && product.related_product_coefficient) {
            const relatedSales = lastWeekSalesMap.get(product.related_product_id) || 0;
            ownSales += relatedSales * product.related_product_coefficient;
          }
          
          return {
            ...product,
            last_seven_days_sales: ownSales,
            last_week_sales: ownSales,
          };
        });

        this.ProductListFiltered = Array.from(this.ProductList);
      }
      this.updateAverageSalesRange();

    } catch (error) {
      console.error('加載銷售數據時發生錯誤:', error);
      this.message.error('加載銷售數據失敗');
    } finally {
      this.isSalesDataLoading = false;
    }
  }

  async getOrderItemsByProductId(productId: number): Promise<DailySales[]> {
    try {
      // 查找當前產品
      const product = this.ProductList.find(p => p.id === productId);
      
      // 確定需要查詢的產品ID列表
      const productIds: number[] = [productId];
      
      // 如果有相關產品，添加到查詢列表
      if (product && product.related_product_id && product.related_product_coefficient) {
        const relatedProductId: number = product.related_product_id;
        const coefficient: number = product.related_product_coefficient;
        productIds.push(relatedProductId);
        
        // 獲取所有相關產品的訂單數據
        const response = await this.webService.getOrderItemsByProductIds(productIds);

        if (response?.body) {
          const orderItems = response.body;

          // 處理產品的銷售數據，分開主產品和相關產品
          const mainProductSalesMap = new Map<string, number>();
          const relatedProductSalesMap = new Map<string, number>();

          // 收集所有銷售數據
          orderItems.forEach(item => {
            const orderDate = item.order?.order_time?.split('T')[0];
            const quantity = Number(item.quantity);
            const itemProductId = item.product?.id;

            if (orderDate && itemProductId) {
              if (itemProductId === productId) {
                // 主產品銷售數據
                mainProductSalesMap.set(orderDate, (mainProductSalesMap.get(orderDate) || 0) + quantity);
              } else if (itemProductId === relatedProductId) {
                // 相關產品銷售數據
                relatedProductSalesMap.set(orderDate, (relatedProductSalesMap.get(orderDate) || 0) + quantity);
              }
            }
          });

          // 合併主產品和相關產品的銷售數據
          const combinedSalesMap = new Map<string, number>();
          
          // 添加主產品銷售數據
          mainProductSalesMap.forEach((quantity, date) => {
            combinedSalesMap.set(date, quantity);
          });
          
          // 添加相關產品銷售數據（乘以係數）
          relatedProductSalesMap.forEach((quantity, date) => {
            const currentQuantity = combinedSalesMap.get(date) || 0;
            combinedSalesMap.set(date, currentQuantity + (quantity * coefficient));
          });

          // 轉換為數組並按日期排序
          let dailySales = Array.from(combinedSalesMap.entries())
            .map(([date, qty]) => ({
              date,
              quantity: qty
            }))
            .sort((a, b) => a.date.localeCompare(b.date)); // 按日期升序排序

          if (dailySales.length > 0) {
            // 找到第一個非零銷量的索引
            const firstNonZeroIndex = dailySales.findIndex(sale => sale.quantity > 0);
            // 找到最後一個非零銷量的索引
            const lastNonZeroIndex = dailySales.map(sale => sale.quantity > 0).lastIndexOf(true);

            if (firstNonZeroIndex !== -1 && lastNonZeroIndex !== -1) {
              // 獲取日期範圍
              const startDate = new Date(dailySales[firstNonZeroIndex].date);
              const endDate = new Date(dailySales[lastNonZeroIndex].date);

              // 創建完整的日期範圍數組
              const fullDateRange: DailySales[] = [];
              const currentDate = new Date(startDate);

              while (currentDate <= endDate) {
                const dateStr = currentDate.toISOString().split('T')[0];
                const existingSale = dailySales.find(sale => sale.date === dateStr);

                fullDateRange.push({
                  date: dateStr,
                  quantity: existingSale ? existingSale.quantity : 0
                });

                currentDate.setDate(currentDate.getDate() + 1);
              }

              dailySales = fullDateRange;
            }
          }

          // 按日期降序排序最終結果
          dailySales = dailySales.sort((a, b) => b.date.localeCompare(a.date));

          // 存储该产品的销售数据
          this.salesDetailsMap.set(productId, dailySales);

          return dailySales;
        }
      } else {
        // 如果沒有相關產品，只查詢主產品
        const response = await this.webService.getOrderItemsByProductIds([productId]);
        
        if (response?.body) {
          const orderItems = response.body;
          const dateSalesMap = new Map<string, number>();

          // 收集所有銷售數據
          orderItems.forEach(item => {
            const orderDate = item.order?.order_time?.split('T')[0];
            const quantity = Number(item.quantity);

            if (orderDate) {
              dateSalesMap.set(orderDate, (dateSalesMap.get(orderDate) || 0) + quantity);
            }
          });

          // 轉換為數組並按日期排序
          let dailySales = Array.from(dateSalesMap.entries())
            .map(([date, qty]) => ({
              date,
              quantity: qty
            }))
            .sort((a, b) => a.date.localeCompare(b.date)); // 按日期升序排序

          if (dailySales.length > 0) {
            // 找到第一個非零銷量的索引
            const firstNonZeroIndex = dailySales.findIndex(sale => sale.quantity > 0);
            // 找到最後一個非零銷量的索引
            const lastNonZeroIndex = dailySales.map(sale => sale.quantity > 0).lastIndexOf(true);

            if (firstNonZeroIndex !== -1 && lastNonZeroIndex !== -1) {
              // 獲取日期範圍
              const startDate = new Date(dailySales[firstNonZeroIndex].date);
              const endDate = new Date(dailySales[lastNonZeroIndex].date);

              // 創建完整的日期範圍數組
              const fullDateRange: DailySales[] = [];
              const currentDate = new Date(startDate);

              while (currentDate <= endDate) {
                const dateStr = currentDate.toISOString().split('T')[0];
                const existingSale = dailySales.find(sale => sale.date === dateStr);

                fullDateRange.push({
                  date: dateStr,
                  quantity: existingSale ? existingSale.quantity : 0
                });

                currentDate.setDate(currentDate.getDate() + 1);
              }

              dailySales = fullDateRange;
            }
          }

          // 按日期降序排序最終結果
          dailySales = dailySales.sort((a, b) => b.date.localeCompare(a.date));

          // 存储该产品的销售数据
          this.salesDetailsMap.set(productId, dailySales);

          return dailySales;
        }
      }

      // 如果没有数据，返回空数组
      return [];

    } catch (error) {
      console.error('獲取訂單項目數據失敗:', error);
      this.message.error('獲取訂單數據失敗');
      return [];
    }
  }
  async getOrderItemsByProductIdWithLocation(productId: number): Promise<LocationDailySales[]> {
  try {
    
    const response = await this.webService.getOrderItemsByProductIds([productId]);
   

    if (response?.body) {
      const orderItems = response.body;

      // 使用复合键(locationId-date)来存储销售数据
      const locationDateSalesMap = new Map<string, LocationDailySales>();

      // 收集所有銷售數據,按地点和日期分组
      orderItems.forEach(item => {
        const orderDate = item.order?.order_time?.split('T')[0];
        const quantity = Number(item.quantity);
        const locationId = item.retail_location_id;

        if (orderDate && locationId) {
          const key = `${locationId}-${orderDate}`;
          const existing = locationDateSalesMap.get(key);
          
          if (existing) {
            existing.quantity += quantity;
          } else {
            locationDateSalesMap.set(key, {
              date: orderDate,
              quantity: quantity,
              location_id: locationId
            });
          }
        }
      });

      // 获取所有不重复的日期和地点
     const allDates = new Set(orderItems.map(item => item.order?.order_time?.split('T')[0]).filter((date): date is string => Boolean(date)));
     const allLocations = new Set(orderItems.map(item => item.retail_location_id).filter((id): id is number => Boolean(id)));

      // 确保每个地点在每个日期都有记录
      const fullDateRange: LocationDailySales[] = [];

      if (allDates.size > 0 && allLocations.size > 0) {
        const dates = Array.from(allDates).sort();
        const locations = Array.from(allLocations);

        // 找到第一个和最后一个有销售的日期
        const startDate = new Date(dates[0] as string);
        const endDate = new Date(dates[dates.length - 1] as string);

        // 为每个地点创建完整的日期范围记录
        locations.forEach(locationId => {
          const currentDate = new Date(startDate);

          while (currentDate <= endDate) {
            const dateStr = currentDate.toISOString().split('T')[0];
            const key = `${locationId}-${dateStr}`;
            const existingSale = locationDateSalesMap.get(key);

            fullDateRange.push(existingSale || {
              date: dateStr,
              quantity: 0,
              location_id: Number(locationId)
            });

            currentDate.setDate(currentDate.getDate() + 1);
          }
        });
      }

      // 按地点和日期降序排序
      const sortedSales = fullDateRange.sort((a, b) => {
        // 首先按地点ID排序
        if (a.location_id !== b.location_id) {
          return a.location_id - b.location_id;
        }
        // 然后按日期降序排序
        return b.date.localeCompare(a.date);
      });

      // 存储该产品的销售数据
      this.salesLocationDetailsMap.set(productId, sortedSales);

     
      return sortedSales;
    }
      
    return [];

  } catch (error) {
    console.error('獲取訂單項目數據失敗:', error);
    this.message.error('獲取訂單數據失敗');
    return [];
  }
}

  getDetailedSalesHistory(productId: number): DailySales[] {
    return this.salesDetailsMap.get(productId) || [];
  }

  // 修改格式化日期方法名
  formatSalesDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }



  reload() {
    this.isSpinning = true;
    this.cacheService.allProducts(true).then(products => {
      this.ProductList = products.filter((product: any) => product.status !== 'deleted');
      this.ProductListFiltered = Array.from(this.ProductList);
      // this.collectAllCountries();
      this.setFilterOptions();
      this.isSpinning = false;
    });
    this.getLatestPurchases();
  }

  search() {
    this.ProductListFiltered = this.ProductList.filter(product => {
      const productNameMatch =
        !this.searchParam.product_name ||
        product.name.toLowerCase().includes(this.searchParam.product_name.toLowerCase());

      /* const barcodeMatch = !this.searchParam.barcode || product.barcode.includes(this.searchParam.barcode);
       */
      const barcodeMatch =
        !this.searchParam.barcode ||
        (product.barcode && product.barcode.toString().includes(this.searchParam.barcode.toString()));

      const countryMatch =
        this.searchParam.country === null ||
        this.searchParam.country.length === 0 ||
        (product.country &&
          ((product.country.name_zh && this.searchParam.country.includes(product.country.name_zh)) ||
            (product.country.name_zh && this.searchParam.country.includes(product.country.name_zh))));

      const brandListMatch =
        this.searchParam.brand_list === null ||
        this.searchParam.brand_list.length === 0 ||
        (product.brand && this.searchParam.brand_list.includes(product.brand.name));

      const firstLevelNameMatch =
        this.searchParam.first_level_name === null ||
        this.searchParam.first_level_name.length === 0 ||
        (product.category && this.searchParam.first_level_name.includes(product.category.first_level_name));

      const secondLevelNameMatch =
        this.searchParam.second_level_name === null ||
        this.searchParam.second_level_name.length === 0 ||
        (product.category && this.searchParam.second_level_name.includes(product.category.second_level_name));

      const thirdLevelNameMatch =
        this.searchParam.third_level_name === null ||
        this.searchParam.third_level_name.length === 0 ||
        (product.category && this.searchParam.third_level_name.includes(product.category.third_level_name));

      const buyerMatch = !this.searchParam.buyer_id || product.buyer_id === parseInt(this.searchParam.buyer_id);

      const purchaseDateMatch =
        !this.searchParam.purchase_date_range ||
        !product.latest_purchase?.purchase_date ||
        (new Date(product.latest_purchase.purchase_date) >= this.searchParam.purchase_date_range[0] &&
          new Date(product.latest_purchase.purchase_date) <= this.searchParam.purchase_date_range[1]);
        
    const supplierMatch =
      !this.searchParam.supplier_name?.length ||
      this.searchParam.supplier_name.some(supplierName => {
        const zhName = product.latest_purchase?.supplier_name_zh;
        const enName = product.latest_purchase?.supplier_name_en;
        
        // 處理複合名稱格式 "中文名稱/英文名稱"
        if (zhName && enName && supplierName === `${zhName}/${enName}`) {
          return true;
        }
        // 處理單一名稱格式
        return supplierName === zhName || supplierName === enName;
      });
      
    // 添加庫存操作過濾（追貨/清貨）
    const stockActionMatch = 
      !this.searchParam.stock_action || 
      this.searchParam.stock_action === '' || 
      this.determineStockAction(product as ProductWithInventory) === this.searchParam.stock_action;
      
    return (
      productNameMatch &&
      barcodeMatch &&
      countryMatch &&
      brandListMatch &&
      firstLevelNameMatch &&
      secondLevelNameMatch &&
      thirdLevelNameMatch &&
      buyerMatch &&
      purchaseDateMatch &&
      supplierMatch &&
      stockActionMatch
    );
  });
  this.setFilterOptions();
}

  onSearch(): void {
    this.search();
    this.setFilterOptions();
  }

  setTableSize(size: NzTableSize): void {
    this.tableSize = size;
    this.TABLE_SIZE_OPTIONS.forEach(option => (option.selected = option.value === size));
  }

  syncToShopify() {
    this.isSpinning = true;
    this.webService
      .syncProductsFromShopify()
      .then(response => {
        this.message.success('Sync successfully');
        this.isSpinning = false;
      })
      .catch(error => {
        this.message.error('Failed to sync');
      });
  }

  async syncOrdersToShopify() {
    try {
      this.isSpinning = true;

      // 創建同步記錄
      const syncData: CreateShopifyProductSyncDto = {
        sync_type: 'all',  // 或 'all'，根據你的需求
        sync_time: new Date(),
        status: 'synced',
        error_message: undefined
      };

      // 先創建同步記錄
      await this.webService.createShopifyProductSync(syncData);

      // 執行同步操作
      await this.webService.syncOrdersFromShopify();
      
      // 同步成功後更新狀態
      this.message.success('同步成功');
    } catch (error) {
      console.error('同步失敗:', error);
      this.message.error('同步失敗');
    } finally {
      this.isSpinning = false;
    }
  }

  onHeaderDoubleClick() {
    this.showSyncButton = true;
  }

  getTransferDetails(shopifyId: string): Array<{ transfer_date: string; actual_transfer_quantity: number }> {
    const transfers = this.transferDetails.get(shopifyId) || [];
    return transfers.sort((a, b) => new Date(a.transfer_date).getTime() - new Date(b.transfer_date).getTime());
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
  }

  calculateAverageSales(productId: number): string {
    const details = this.getSalesDetails(productId);
    const totalSales = details.reduce((sum, day) => sum + day.quantity, 0);
    const daysWithSales = details.filter(day => day.quantity > 0).length || 1;
    return (totalSales / daysWithSales).toFixed(1);
  }

  // 添加获取销售详情的方
  getSalesDetails(productId: number): Array<{ sale_date: string; quantity: number }> {
    return this.salesDetails.get(productId) || [];
  }

  // 添加獲取最近出貨單的方法
  // getRecentTransfer(productId: string): string {}


  public formatSalesWithAverage(product: Product): string {
    const details = this.getSalesDetails(product.id);
    const totalSales = product.last_seven_days_sales || 0;

    // 如果没有销售记录，直接返回
    if (details.length === 0) {
      return `${totalSales} (0)`;
    }

    // 获取今天的日期
    const today = new Date().toISOString().split('T')[0];

    // 按日期排序，并过滤掉今天的数据
    const sortedDetails = details
      .filter(day => day.sale_date !== today)
      .sort((a, b) => a.sale_date.localeCompare(b.sale_date));

    // 找到第一个非零销量的日期索引
    const firstNonZeroIndex = sortedDetails.findIndex(day => day.quantity > 0);

    // 如果没有非零销量，返回0
    if (firstNonZeroIndex === -1) {
      return `${totalSales} (0)`;
    }

    // 计算从第一个非零销量到最后一天的天数
    const dayCount = sortedDetails.length - firstNonZeroIndex;

    // 计算平均值（总销量除以从第一次销售到最后一天的天数）
    const averageSales = (totalSales / dayCount).toFixed(1);

    return `${totalSales} (${averageSales})`;
  }

  async getLatestPurchases() {
    try {
      const productIds = this.ProductList.map(product => product.id);

      if (productIds.length === 0) {
        return;
      }

      const response = await this.webService.findLatestPurchaseOrderByProductIds(productIds);
      const latestPurchases = response.data;

      // 更新 ProductList，使用 product_id 而非 shopify_id 匹配
      this.ProductList = this.ProductList.map(product => ({
        ...product,
        latest_purchase: latestPurchases.find(p => p.product_id === product.id) || {
          supplier_name_zh: null,
          supplier_name_en: null,
          purchase_date: null,
          quantity: null,
        },
      }));
      console.log('2389this.ProductList', this.ProductList);
      
      // 更新過濾後的列表
      this.ProductListFiltered = [...this.ProductList];
      this.setFilterOptions();
    } catch (error) {
      console.error('獲取最近採購數據失敗:', error);
      this.message.error('加載最近採購數據失敗');
    }
  }

  // 日期範圍變更處理
  onDateRangeChange(dateRange: [Date, Date]): void {
    this.searchParam.purchase_date_range = dateRange;
    this.ProductListFiltered = this.filterProducts();
  }
  getBuyerName(buyerId: string): string {
    const product = this.ProductList.find(p => p.buyer_id?.toString() === buyerId);
    return product?.buyer_name || buyerId;
  }

  filterProducts(): Product[] {
    return this.ProductList.filter(product => {
      // 處理採購日期範圍過濾
      if (this.searchParam.purchase_date_range) {
        const [startDate, endDate] = this.searchParam.purchase_date_range;
        const purchaseDate = product.latest_purchase?.purchase_date
          ? new Date(product.latest_purchase.purchase_date)
          : null;

        if (!purchaseDate || purchaseDate < startDate || purchaseDate > endDate) {
          return false;
        }
      }

      // 處理庫存範圍過濾
      const inventory = product.total_inventory || 0;
      if (inventory < this.inventoryRange[0] || inventory > this.inventoryRange[1]) {
        return false;
      }

      // 處理毛利率範圍過濾
      const margin = product.gross_margin || 0;
      if (margin < this.marginRange[0] || margin > this.marginRange[1]) {
        return false;
      }

      // 處理七日平均配貨範圍過濾
      const averageDistribution = product.total_actual_transfer_quantity || 0;
      if (averageDistribution < this.averageDistributionRange[0] ||
        averageDistribution > this.averageDistributionRange[1]) {
        return false;
      }

      // 處理七日平均銷售範圍過濾
      const averageSales = product.last_seven_days_sales || 0;
      if (averageSales < this.averageSalesRange[0] ||
        averageSales > this.averageSalesRange[1]) {
        return false;
      }

      // 處理到期日期範圍過濾
      if (this.searchParam.expiry_date_range) {
        const [startDate, endDate] = this.searchParam.expiry_date_range;
        const expiryDate = product.expiry_date ? new Date(product.expiry_date) : null;

        if (!expiryDate || expiryDate < startDate || expiryDate > endDate) {
          return false;
        }
      }

      // 處理庫存操作過濾（追貨/清貨）
 

      return true;
    });
  }

  isLoadingInventory(shopifyId: string): boolean {
    return this.loadingInventoryMap.get(shopifyId) || false;
  }
  hasNonZeroInventory(details: { locationId: string; available: number }[] | undefined): boolean {
    if (!details || !Array.isArray(details)) return false;
    return (
      details.some(detail => detail.available !== 0) &&
      this.filterValidLocations(details).some(detail => detail.available !== 0)
    );
  }




  updateInventoryRange() {
    if (this.ProductList && this.ProductList.length > 0) {
      const inventories = this.ProductList.map(p => p.total_inventory || 0);
      this.minInventory = 0;  // 最小值保持為 0
      this.maxInventory = Math.max(...inventories);
      this.inventoryRange = [this.minInventory, this.maxInventory];
    }
  }
  async fetchSimpleInventory() {
    try {
      this.loadingInventory = true;

      const locations = await this.cacheService.allLocations();
      const activeLocationIds = locations
        .filter(location => location.status !== 'closed')
        .map(location => location.id);

      const response = await this.webService.findInventoriesSimple();

      if (response && Array.isArray(response)) {
        const productInventoryMap = new Map<number, number>();
        const productLocationMap = new Map<number, LocationInventory[]>();
        const warehouseInventoryMap = new Map<number, number>();
        // 新增：记录每个产品有库存的门店数量
        const productLocationCountMap = new Map<number, number>();

        response.forEach(item => {
          const { product_id, location_id, available } = item;
          const availableQty = Number(available) || 0;

          // 只处理活跃位置的记录
          if (!activeLocationIds.includes(location_id)) {
            return;
          }

          // 更新总库存
          const currentTotal = productInventoryMap.get(product_id) || 0;
          productInventoryMap.set(product_id, currentTotal + availableQty);

          // 如果是仓库库存 (location_id === 1)，记录到仓库库存Map
          if (location_id === 1) {
            warehouseInventoryMap.set(product_id, availableQty);
          }

          // 更新位置详细信息（只保存非零库存）
          if (availableQty !== 0) {
            // 更新有库存的门店计数（排除仓库location_id === 1）
            if (location_id !== 1) {
              productLocationCountMap.set(
                product_id, 
                (productLocationCountMap.get(product_id) || 0) + 1
              );
            }

            if (!productLocationMap.has(product_id)) {
              productLocationMap.set(product_id, []);
            }
            const locationDetails = productLocationMap.get(product_id)!;
            const locationName = locations.find(loc => loc.id === location_id)?.name_zh || location_id.toString();

            locationDetails.push({
              locationName,
              available: availableQty
            });
          }
        });

        // 对每个产品的位置库存进行排序
        productLocationMap.forEach(locations => {
          locations.sort((a, b) => b.available - a.available);
        });

        // 更新产品列表，添加仓库库存和有库存的门店数量
        this.ProductList = this.ProductList.map(product => ({
          ...product,
          total_inventory: productInventoryMap.get(product.id) || 0,
          warehouse_inventory: warehouseInventoryMap.get(product.id) || 0,
          location_inventories: productLocationMap.get(product.id) || [],
          locations_with_inventory: productLocationCountMap.get(product.id) || 0 // 新增：有库存的门店数量
        }));
        

        // 计算库存范围
        const inventories = this.ProductList.map(p => p.total_inventory || 0);
        const minInventory = Math.min(...inventories);
        const maxInventory = Math.max(...inventories);

        this.minInventory = minInventory;
        this.maxInventory = maxInventory > minInventory ? maxInventory : minInventory + 1000;
        this.inventoryRange = [this.minInventory, this.maxInventory];

        // 更新过滤后的列表
        this.ProductListFiltered = [...this.ProductList];
      }

    } catch (error) {
      console.error('获取简化库存数据失败:', error);
      this.message.error('获取库存数据失败');
    } finally {
      this.loadingInventory = false;
    }
  }

  ngOnDestroy() {
    this.loadingInventoryMap.clear();
    // ... 其他清理代码
    this.isLocationOrderLoading = false;
  }

  public calculateEstimatedDailySales(product: ProductWithInventory): number {
    const weeklyRate = product.last_week_sales || 0;
    const monthlyRate = weeklyRate / 6; // 轉換為月銷售量
    const totalInventory = product.total_inventory || 0;
    const salesDetails = this.salesDetails.get(product.id) || [];

    // 获取今天的日期
    const today = new Date().toISOString().split('T')[0];

    // 过滤掉今天的数据，只保留过去6天的数据
    const pastSixDays = salesDetails
      .filter(detail => detail.sale_date !== today)
      .sort((a, b) => a.sale_date.localeCompare(b.sale_date));

    // 如果没有数据，返回无限大
    if (pastSixDays.length === 0) {
      return Infinity;
    }

    // 找到第一个非零销量的日期索引
    const firstNonZeroIndex = pastSixDays.findIndex(day => day.quantity > 0);

    // 如果没有非零销量，返回无限大
    if (firstNonZeroIndex === -1) {
      return Infinity;
    }

    // 计算从第一个非零销量日期到最后一天的总销量和天数
    const relevantDays = pastSixDays.slice(firstNonZeroIndex);
    const totalSales = relevantDays.reduce((sum, day) => sum + day.quantity, 0);
    const dayCount = relevantDays.length;

    // 计算日均销量并转换为月销量（乘以30）
    const dailyAverage = totalSales / dayCount;


    // 如果月销量为0，返回无限大
    if (dailyAverage === 0) {
      return Infinity;
    }

    // 计算预计销售天数
    return totalInventory / dailyAverage;
  }

  async handleSalesDetailsClick(productId: number) {
    // 设置 popover 为可见
    this.popoverVisibilityMap.set(productId, true);

    // 如果已经有数据，直接计算平均值
    if (this.salesDetailsMap.has(productId)) {
      const sales = this.salesDetailsMap.get(productId) || [];
      // 只计算有销售的天数
      const salesDays = sales.filter(sale => sale.quantity > 0);
      if (salesDays.length > 0) {
        const totalSales = salesDays.reduce((sum, sale) => sum + sale.quantity, 0);
        const average = totalSales / salesDays.length;
        this.salesAverages.set(productId, average);
      }
      return;
    }

    // 没有数据时加载
    this.salesLoadingMap.set(productId, true);

    try {
      const salesData = await this.getOrderItemsByProductId(productId);
      // 获取数据后计算平均值
      const salesDays = salesData.filter(sale => sale.quantity > 0);
      if (salesDays.length > 0) {
        const totalSales = salesDays.reduce((sum, sale) => sum + sale.quantity, 0);
        const average = totalSales / salesDays.length;
        this.salesAverages.set(productId, average);
      }
    } finally {
      this.salesLoadingMap.set(productId, false);
    }
  }

  isSalesDetailsLoading(productId: number): boolean {
    return this.salesLoadingMap.get(productId) || false;
  }

  getAverageSales(productId: number): number | null {
    return this.salesAverages.get(productId) || null;
  }

  getPopoverVisible(productId: number): boolean {
    return this.popoverVisibilityMap.get(productId) || false;
  }

  setPopoverVisible(productId: number, visible: boolean): void {
    this.popoverVisibilityMap.set(productId, visible);
  }

  public calculateDaysUntilExpiry(expiryDate: string | null | undefined): number | null {
    if (!expiryDate) return null;

    const today = new Date();
    today.setHours(0, 0, 0, 0);  // 设置时间为当天开始

    const expiry = new Date(expiryDate);
    if (isNaN(expiry.getTime())) return null; // 如果日期无效，返回 null

    expiry.setHours(0, 0, 0, 0);  // 设置时间为当天开始

    const diffTime = expiry.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  // 添加滑動條變化處理方法
  onInventoryRangeChange(range: [number, number]) {
    this.inventoryRange = range;
    this.ProductListFiltered = this.filterProducts();
  }

  // 處理毛利率滑動條變化
  onMarginRangeChange(range: [number, number]) {
    this.marginRange = range;
    this.ProductListFiltered = this.filterProducts();
  }

  // 更新毛利率範圍的獨立函數
  updateMarginRange() {
    if (this.ProductList && this.ProductList.length > 0) {
      const margins = this.ProductList.map(p => p.gross_margin || 0);
      const minMargin = -1;
      const maxMargin = Math.max(...margins);

      // 更新毛利率範圍
      this.minMargin = minMargin;
      this.maxMargin = maxMargin > minMargin ? maxMargin : minMargin + 0.5;
      this.marginRange = [this.minMargin, this.maxMargin];
    }
  }

  // 添加更新範圍的方法
  updateAverageDistributionRange() {
    if (this.ProductList && this.ProductList.length > 0) {
      const averages = this.ProductList.map(p => p.total_actual_transfer_quantity || 0);
      const minAverage = Math.min(...averages);
      const maxAverage = Math.max(...averages);

      // 更新範圍
      this.minAverageDistribution = minAverage;
      this.maxAverageDistribution = maxAverage;
      this.averageDistributionRange = [this.minAverageDistribution, this.maxAverageDistribution];
    }
  }

  // 處理滑動條變化
  onAverageDistributionRangeChange(range: [number, number]) {
    this.averageDistributionRange = range;
    this.ProductListFiltered = this.filterProducts();
  }

  // 添加更新範圍的方法
  updateAverageSalesRange() {
    if (this.ProductList && this.ProductList.length > 0) {
      const averages = this.ProductList.map(p => p.last_seven_days_sales || 0);
      const minAverage = Math.min(...averages);
      const maxAverage = Math.max(...averages);

      // 更新範圍
      this.minAverageSales = minAverage;
      this.maxAverageSales = maxAverage;
      this.averageSalesRange = [this.minAverageSales, this.maxAverageSales];
    }
  }

  // 處理滑動條變化
  onAverageSalesRangeChange(range: [number, number]) {
    this.averageSalesRange = range;
    this.ProductListFiltered = this.filterProducts();
  }

  updateMarks() {
    this.inventoryMarks = {
      [this.minInventory]: this.minInventory.toString(),
      [this.maxInventory]: this.maxInventory.toString()
    };

    this.averageDistributionMarks = {
      [this.minAverageDistribution]: this.minAverageDistribution.toString(),
      [this.maxAverageDistribution]: this.maxAverageDistribution.toString()
    };

    this.averageSalesMarks = {
      [this.minAverageSales]: this.minAverageSales.toString(),
      [this.maxAverageSales]: this.maxAverageSales.toString()
    };
  }

  toggleFilters() {
    this.showMoreFilters = !this.showMoreFilters;
  }

  // 日期範圍變更處理
  onExpiryDateRangeChange(dateRange: [Date, Date]): void {
    if (dateRange) {
      // 将开始日期设置为当天的开始时间
      dateRange[0].setHours(0, 0, 0, 0);
      // 将结束日期设置为当天的结束时间
      dateRange[1].setHours(23, 59, 59, 999);
    }
    this.searchParam.expiry_date_range = dateRange;
    this.ProductListFiltered = this.filterProducts();
  }

  // 添加計算每日所需銷售量的方法
  private calculateRequiredDailySales(product: Product): number | null {
    const inventory = product.total_inventory || 0;
    const daysUntilExpiry = this.calculateDaysUntilExpiry(product.expiry_date?.toString());

    // 如果沒有到期日，返回 null
    if (daysUntilExpiry === null) return null;

    // 如果已經過期（天數為負），返回 Infinity
    if (daysUntilExpiry <= 0) return null;

    // 如果沒有庫存，返回 0
    if (inventory === 0) return 0;

    // 計算每日所需銷售量
    return inventory / daysUntilExpiry;
  }

  // private getWarehouseInventory(product: Product): number {
  //   if (!product.location_inventories) return 0;

  //   const warehouseInventory = product.location_inventories.find(
  //     inventory => inventory.locationId === 1
  //   );

  //   return warehouseInventory ? warehouseInventory.available : 0;
  // }

  // 添加分類變更處理方法
  onFirstLevelChange() {
    // 清空二級和三級分類的選擇
    this.searchParam.second_level_name = [];
    this.searchParam.third_level_name = [];
    this.onSearch();
  }

  onSecondLevelChange() {
    // 清空三級分類的選擇
    this.searchParam.third_level_name = [];
    this.onSearch();
  }

  async getLatestSyncTime(): Promise<void> {
    try {
      const latestSync = await this.webService.getLatestShopifySync();
      console.log('latestSync', latestSync);
      this.latestSyncTime = latestSync.sync_time;
    } catch (error) {
      console.error('获取最新同步时间失败:', error);
      this.latestSyncTime = null;
    }
  }

    async loadProductsLastSaleDays(): Promise<void> {
      try {
        const response = await this.webService.getAllProductsWithDaysSinceLastSale();
        console.log('2621response', response);
        if (response && Array.isArray(response)) {
          // 创建一个查找对象，比Map更直接
          const lastSaleInfo = {};
          
          response.forEach(item => {
            if (item.product_id !== undefined) {
              lastSaleInfo[item.product_id] = {
                days_since_last_sale: item.days_since_last_sale,
              };
            }
          });
          
          // 直接更新产品列表
          this.ProductList = this.ProductList.map(product => ({
          ...product,
          days_since_last_sale: lastSaleInfo[product.id]?.days_since_last_sale !== undefined ? 
          lastSaleInfo[product.id].days_since_last_sale : null,
          }));
          
          // 更新过滤后的列表
          this.ProductListFiltered = [...this.ProductList];
        }
      } catch (error) {
        console.error('加載產品最後銷售日期失敗:', error);
        this.message.error('加載產品最後銷售日期失敗');
      }
    }
    

  // 將原來的 determineStockAction 方法拆分為兩個獨立的方法
  needsRestock(product: ProductWithInventory): boolean {
    // 追貨邏輯
    // 使用最近七日销售量计算平均值
    const lastSevenDaysSales = product.last_seven_days_sales || 0;
    const dailyAverageSales = lastSevenDaysSales / 6; // 除以6天（不包括今天）
    const inventory = product.total_inventory || 0;
    
    // 如果平均銷售量大於0且庫存低於7天的銷售量，且庫存大於等於12，則需要追貨
    return dailyAverageSales > 0 && inventory < (dailyAverageSales * 7) && inventory >= 12;
  }

  needsClearance(product: ProductWithInventory): boolean {
    // 清貨邏輯
    const daysUntilExpiry = this.calculateDaysUntilExpiry(product.expiry_date?.toString());
    // 使用最近七日销售量计算平均值
    const lastSevenDaysSales = product.last_seven_days_sales || 0;
    const dailyAverageSales = lastSevenDaysSales / 6; // 除以6天（不包括今天）
    const inventory = product.total_inventory || 0;
    
    // 如果到期日少於30天且庫存大於預計銷售量，且庫存大於等於12，則需要清貨
    return (daysUntilExpiry !== null && daysUntilExpiry < 30) && 
           (dailyAverageSales <= 0 || inventory > (dailyAverageSales * daysUntilExpiry)) &&
           inventory >= 12;
  }

  // 保留原方法用於過濾和其他地方
  determineStockAction(product: ProductWithInventory): string {
    if (this.needsRestock(product)) {
      return '追貨';
    } else if (this.needsClearance(product)) {
      return '清貨';
    } else {
      return '-';
    }
  }

  // ... existing code ...

  // 添加切換操作列顯示的方法
  toggleOperationsColumn(): void {
    this.showOperationsColumn = !this.showOperationsColumn;
  }
}
