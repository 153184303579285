import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { UserActivity } from '../models/userActivity';
import { Supplier } from '../models/supplier';
import { PurchaseOrder, PurchaseOrderFile } from '../models/purchaseOrder';
import { StateService } from './state.service';
import { HttpResponse } from '@angular/common/http';
import { Category, Product, Brand } from '../models/product';
import { PurchaseOrderProduct } from '../models/purchaseOrder';
import { Country } from '../models/country';
import { Location } from '../models/location';
import { InventoryError } from '../models/inventory_error';
import { StockTake } from '../models/stock-take';
import { SupplierFile } from '../models/supplier-file';
import { ProductFile } from '../models/product-file';
import { BrandFile } from '../models/brand-file';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { ProductPriceChange } from '../models/product-price-change';
import { firstValueFrom } from 'rxjs';
import { timeout, retryWhen, delay, take, catchError } from 'rxjs/operators';
import { ShopifyProductSync, CreateShopifyProductSyncDto } from '../models/shopify-product-sync';

@Injectable({
  providedIn: 'root',
})
export class WebService {
  private selectedIds = new Set<number>();

  constructor(private http: HttpClient, private router: Router, private state: StateService) {
    this.setUserRoles();
  }

  get headers(): HttpHeaders {
    return this.getHttpHeader();
  }

  get fileUploadHeaders(): HttpHeaders {
    return this.getHttpHeader(true);
  }

  getHttpHeader(isFileUpload: boolean = false): HttpHeaders {
    let headers = new HttpHeaders();

    // 添加认证 token
    headers = headers.set('Authorization', 'Bearer Vq26dbQ9oQbZfCVIjwCHqBqYP47z4flZrv3sAbM75u7MZxta7YFr9PmC8qegfZX1');

    // 如果不是文件上传,则设置 Content-Type 为 application/json
    if (!isFileUpload) {
      headers = headers.set('Content-Type', 'application/json');
    }

    return headers;
  }

  addId(id: number) {
    this.selectedIds.add(id);
  }

  removeId(id: number) {
    this.selectedIds.delete(id);
  }

  getSelectedIds(): Set<number> {
    return this.selectedIds;
  }

  clearSelectedIds() {
    this.selectedIds.clear();
  }

  getSupplierUploadUrl() {
    return environment.api_endpoint + `supplier/upload`;
  }

  getProductUploadUrl() {
    return environment.api_endpoint + `products/upload`;
  }

  getBrandUploadUrl() {
    return environment.api_endpoint + `brands/upload`;
  }

  getPurchaseOrderUploadUrl() {
    return environment.api_endpoint + `purchase-orders/upload`;
  }
  deleteSupplier(id: number, user_id: number): Observable<any> {
    const url = `${environment.api_endpoint}supplier/${id}?user_id=${user_id} `;
    return this.http.delete(url, { headers: this.headers });
  }
  //About Login Logic: 關於登錄邏輯的方法! 請放在這裏。
  async login(username: string, password: string) {
    const api_url = environment.api_endpoint + 'auth/login';
    const body = { username, password };

    try {
      const res = await this.http
        .post(api_url, body, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();

      if (res && (res.status === 200 || res.status === 201) && res.body) {
        const userInfo = res.body;
        this.state.saveLogin(userInfo);
        console.log('User info stored in localStorage:', userInfo);
      }
      return res;
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  }

  async validateUser(userId?: number) {
    const url = `${environment.api_endpoint}auth/validate-user/${userId}`;
    try {
      const response = await this.http
        .get(url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async checkAutoLoginSession() {
    const user = localStorage.getItem('user');
    if (user) {
      const userInfo = JSON.parse(user);
      const username = userInfo.username;

      try {
        const api_url = environment.api_endpoint + 'auth/check-login-record';
        const res: any = await this.http
          .post(
            api_url,
            { username },
            {
              headers: this.headers,
              observe: 'response',
              responseType: 'json',
              withCredentials: true,
            }
          )
          .toPromise();

        if (res && (res.status === 200 || res.status === 201) && res.body) {
          console.log('User has valid login record, redirecting...');
          this.state.saveLogin(userInfo);
          this.router.navigate(['/products']);
        }
      } catch (error) {
        console.error('Error checking login record:', error);
      }
    }
  }

  //About product service methods：關於產品服務的邏輯方法! 放在這裏。
  async getAllProducts(): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + 'products';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async getAllProductsSimple(): Promise<any> {
  const api_url = `${environment.api_endpoint}products/simple`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取簡化產品列表失敗:', error);
    throw error;
  }
}


  async addProduct(product: any): Promise<any> {
    const url = `${environment.api_endpoint}products`;

    try {
      const response = await this.http.post(url, product, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add product', error);
      throw error;
    }
  }

  async getProductFiles(productId?: number) {
    const url = `${environment.api_endpoint}product-file/product/${productId}`;
    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Error fetching product files:', error);
      throw new Error('Network response was not ok');
    }
  }
  async getAverageProductSales(): Promise<any> {
  const api_url = `${environment.api_endpoint}order_item_snapshots/average-sales`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取產品平均銷售數據失敗:', error);
    throw error;
  }
  }
  async getBrandFiles(brandId?: number) {
    const url = `${environment.api_endpoint}brand-file/brand/${brandId}`;
    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Error fetching brand files:', error);
      throw new Error('Network response was not ok');
    }
  }

  async getPurchaseOrderFiles(purchaseOrderId?: number): Promise<PurchaseOrderFile[]> {
    const url = `${environment.api_endpoint}purchase-order-file/purchase-order/${purchaseOrderId}`;
    try {
      const response = await this.http.get<PurchaseOrderFile[]>(url, { headers: this.headers }).toPromise();
      //console.log('Purchase Order Files', response);
      return response || [];
    } catch (error) {
      console.error('Error fetching purchase order files:', error);
      throw error;
    }
  }

  async getProductSpecifications(productId?: number) {
    const response = await fetch(`${environment.api_endpoint}productspecifications/product/${productId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  }

  async editProduct(id: number, product: any): Promise<any> {
    const url = `${environment.api_endpoint}products/${id}/`;

    try {
      const response = await this.http.post(url, product, { headers: this.headers }).toPromise();
      console.log('Product updated response:', response);
      return response;
    } catch (error) {
      console.error('Failed to edit product', error);
      throw error;
    }
  }

  async productNameExists(name: string): Promise<any> {
    const url = `${environment.api_endpoint}products/is_exist_product_name/${name}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      console.log('Product name exists:', response);
      return response;
    } catch (error) {
      console.error('Failed to get product name', error);
      throw error;
    }
  }

  async productBarcodeExists(barcode: string): Promise<any> {
    const url = `${environment.api_endpoint}products/is_exist_product_barcode/${name}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      console.log('Barcode name exists:', response);
      return response;
    } catch (error) {
      console.error('Failed to get barcode name', error);
      throw error;
    }
  }
  async getLongTermSupplyProducts(): Promise<any> {
  const api_url = `${environment.api_endpoint}products/long-term-supply`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取長期供應產品失敗:', error);
    throw error;
  }
}

  // async editProduct(id: number, product: any) {
  //   const url = `${environment.api_endpoint}products/${id}/`;
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   try {
  //     const response = await fetch(url, {
  //       method: 'Post',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(product),
  //     });
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const result = await response.json();
  //     console.log('Product updated:', result);
  //   } catch (error) {
  //     console.error('Error updating product:', error);
  //   }
  // };

  async deleteProduct(id: number, userId: number) {
    const api_url = environment.api_endpoint + `products/${id}?user_id=${userId}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers, withCredentials: true }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getImageUrl(productId: string, imageType: string) {
    const api_url = environment.api_endpoint + `products/${productId}/image?key=${imageType}`;
    try {
      const res = await this.http.get(api_url, { headers: this.headers, withCredentials: true }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }
  
  async findProductsBySupplier(supplierId: number): Promise<any> {
  const api_url = `${environment.api_endpoint}purchase-orders/products-by-supplier`;
  try {
    const response = await this.http.post(
      api_url, 
      { supplierId }, 
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取供應商相關產品失敗:', error);
    throw error;
  }
}

async findRelatedProducts(productIds: number[]): Promise<any> {
  const api_url = `${environment.api_endpoint}products/related-products`;
  try {
    const response = await this.http.post(
      api_url, 
      { productIds }, 
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取相關產品失敗:', error);
    throw error;
  }
}
async getProductIdsByShopifyIds(shopifyIds: string[]): Promise<any> {
  const api_url = `${environment.api_endpoint}products/shopify-ids-to-product-ids`;
  try {
    const response = await this.http.post(
      api_url, 
      { shopifyIds }, 
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取產品ID失敗:', error);
    throw error;
  }
}


  // getBackImage(productId: string) {
  //   return `/api/products/${productId}/back_image`;
  // }

  // getBarcodeImage(productId: string) {
  //   return `/api/products/${productId}/barcode_image`;
  // }
  // getInnerPackagingImage(productId: string) {
  //   return `/api/products/${productId}/inner_packaging_image`;
  // }
  // getCompareAtPriceImage(productId: string) {
  //   return `/api/products/${productId}/compare_at_price_image`;
  // }

  //About user service methods：關於用戶服務的邏輯方法! 請放在這裏。
  async addUser(user: User): Promise<any> {
    const api_url = environment.api_endpoint + 'users';
    try {
      const res = await this.http.post(api_url, user, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async updateUser(userId: number, updateId: number, updateUserDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}users/${userId}?user_id=${updateId}`;
    try {
      const res = await this.http.put(api_url, updateUserDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(userId: number, updateId: number, newPassword: string): Promise<any> {
    const api_url = environment.api_endpoint + `users/${userId}/reset-password?user_id=${updateId}`;
    try {
      const res = await this.http.patch(api_url, { newPassword }, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async disableUser(user: User): Promise<any> {
    const api_url = environment.api_endpoint + `users/disable/${user.id}`;
    try {
      const res = await this.http.patch(api_url, {}, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getAllUsers(): Promise<any> {
    const api_url = environment.api_endpoint + 'users';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async setUserRoles() {
    this.getAllRoles().then((res: any) => {
      if (res.status >= 200 && res.status < 299) {
        const roles = res.body;
        this.state.setRoles(roles);
      }
    });
  }

  async getAllRoles(): Promise<any> {
    const api_url = environment.api_endpoint + 'roles';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async getOneUser(user: User): Promise<any> {
    const api_url = environment.api_endpoint + `users/${user.id}`;
    try {
      const res = await this.http.get(api_url, { headers: this.headers, withCredentials: true }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addUserActivity(user: UserActivity): Promise<any> {
    const api_url = environment.api_endpoint + 'user-activity';
    try {
      const res = await this.http.post(api_url, user, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getAllUserActivity(): Promise<any> {
    const api_url = environment.api_endpoint + 'user-activity';
    return this.http
      .get(api_url, {
        headers: this.headers,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async getBetweenUserActivityData(startDate: string, endDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}user-activity/between-dates/${startDate}/${endDate}`;

    try {
      const response = await this.http
        .get(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get between days transfer data', error);
      throw error;
    }
  }

  //About supplier service methods：關於供應商服務的邏輯方法! 請放在這裏。
  async getAllSuppliers(): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + 'supplier';
    let params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async addSupplier(supplier: Supplier): Promise<any> {
    const api_url = environment.api_endpoint + 'supplier';
    try {
      const res = await this.http.post(api_url, supplier, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getSupplierDetail(id: number): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + `supplier/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http
      .get<Supplier>(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<Supplier>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async updateSupplier(id: number, user_id: number, supplier: Supplier): Promise<any> {
    const api_url = environment.api_endpoint + `supplier/${id}?user_id=${user_id}`;
    try {
      const res = await this.http.put(api_url, supplier, { headers: this.headers }).toPromise();
      console.log(500, res);
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getSupplierFileSignedUrl(storage_filename: string): Promise<string> {
    const api_url = environment.api_endpoint + 'supplier/file/' + storage_filename;
    try {
      const res = await this.http.get(api_url, { headers: this.headers }).toPromise();
      return res?.['url'] as string;
    } catch (error) {
      console.error('Error fetching supplier file signed URL:', error);
      throw error;
    }
  }

  async supplierNameExistsZh(name: string): Promise<any> {
    const url = `${environment.api_endpoint}supplier/is_exist_supplier_zh/${name}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      console.log(522, response);
      return response;
    } catch (error) {
      console.error('Failed to get supplier_zh name', error);
      throw error;
    }
  }

  async supplierNameExistsEn(name: string): Promise<any> {
    const url = `${environment.api_endpoint}supplier/is_exist_supplier_en/${name}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      console.log(534, response);
      return response;
    } catch (error) {
      console.error('Failed to get supplier_en name', error);
      throw error;
    }
  }

  async supplierCompanyNameExistsZh(name: string): Promise<any> {
    const url = `${environment.api_endpoint}supplier/is_exist_supplier_company_zh/${name}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get supplier_company_zh name', error);
      throw error;
    }
  }

  async supplierCompanyNameExistsEn(name: string): Promise<any> {
    const url = `${environment.api_endpoint}supplier/is_exist_supplier_company_en/${name}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get supplier_company_en name', error);
      throw error;
    }
  }

  async getProductFileSignedUrl(storage_filename: string): Promise<string> {
    const api_url = environment.api_endpoint + 'products/file/' + storage_filename;
    try {
      const res = await this.http.get(api_url, { headers: this.headers }).toPromise();
      return res?.['url'] as string;
    } catch (error) {
      console.error('Error fetching product file signed URL:', error);
      throw error;
    }
  }

  async getBrandFileSignedUrl(storage_filename: string): Promise<string> {
    const api_url = environment.api_endpoint + 'brands/file/' + storage_filename;
    try {
      const res = await this.http.get(api_url, { headers: this.headers }).toPromise();
      return res?.['url'] as string;
    } catch (error) {
      console.error('Error fetching brand file signed URL:', error);
      throw error;
    }
  }

  async getPurchaseOrderFileSignedUrl(storage_filename: string): Promise<string> {
    const api_url = environment.api_endpoint + 'purchase-orders/file/' + storage_filename;
    try {
      const res = await this.http.get(api_url, { headers: this.headers }).toPromise();
      return res?.['url'] as string;
    } catch (error) {
      console.error('Error fetching purchase order file signed URL:', error);
      throw error;
    }
  }

  async addSupplierFile(supplierFile: Partial<SupplierFile>): Promise<any> {
    const api_url = environment.api_endpoint + 'supplier-file';
    try {
      const res = await this.http.post(api_url, supplierFile, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addProductFile(id: number, productFile: Partial<ProductFile>): Promise<any> {
    const api_url = environment.api_endpoint + `product-file?user_id=${id}`;
    try {
      const res = await this.http.post(api_url, productFile, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addBrandFile(brandFile: Partial<BrandFile>): Promise<any> {
    console.log(brandFile);
    const api_url = environment.api_endpoint + 'brand-file';
    try {
      const res = await this.http.post(api_url, brandFile, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addPurchaseOrderFile(purchaseOrderFile: Partial<PurchaseOrderFile>): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-order-file';
    try {
      const res = await this.http.post(api_url, purchaseOrderFile, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteSupplierFile(id: number) {
    const api_url = environment.api_endpoint + `supplier-file/${id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteBrandFile(id: number) {
    const api_url = environment.api_endpoint + `brand-file/${id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteProductFile(user_id: number, id: number) {
    const api_url = environment.api_endpoint + `product-file/${id}?user_id=${user_id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deletePurchaseOrderFile(id: number) {
    const api_url = environment.api_endpoint + `purchase-order-file/${id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  //About purchase order service methods：關於採購單服務的邏輯方法! 請放在這裏。
  async getAllPurchaseOrders(): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-orders';
    const params = new HttpParams().append('relations[]', 'arrival_location');

    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async getAllPurchaseOrdersWithProducts(): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-orders/with-products';
    const params = new HttpParams().append('relations[]', 'arrival_location');

    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async getOnePurchaseOrder(id: number): Promise<any> {
    const api_url = environment.api_endpoint + `purchaseOrders/${id}`;
    try {
      const res = await this.http.get(api_url, { headers: this.headers, withCredentials: true }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async bulkUpdatePurchaseOrders(updatedPurchaseOrders: any[]): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-orders/bulk-update';
    return this.http
      .put(api_url, updatedPurchaseOrders, {
        headers: this.headers,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  async addPurchaseOrder(purchaseOrders: any): Promise<any> {
    const api_url = environment.api_endpoint + 'purchase-orders';
    try {
      // 格式化 best_before_date
      purchaseOrders.purchase_order_products = purchaseOrders.purchase_order_products.map(product => ({
        ...product,
        best_before_date: this.formatDate(new Date(product.best_before_date)),
      }));

      const res = await this.http.post(api_url, purchaseOrders, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      console.error('Error adding purchase order:', error);
      throw error;
    }
  }

  async getPurchaseOrderDetail(id: number): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + `purchase-orders/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http
      .get<PurchaseOrder>(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<PurchaseOrder>) => {
        // console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }
  async getAllPurchaseOrderProducts(): Promise<any> {
  const api_url = environment.api_endpoint + 'purchase-order-products';
  var params = new HttpParams().appendAll({});
  return this.http
    .get(api_url, {
      headers: this.headers,
      params: params,
      observe: 'response',
      responseType: 'json',
      withCredentials: true,
    })
    .toPromise()
    .then(res => {
      console.log('Purchase order products:', res);
      return res;
    })
    .catch(error => {
      console.error('Error fetching purchase order products:', error);
      return error;
    });
}

  async getPurchaseOrderProductsDetail(id: number): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + `purchase-order-products/order/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http
      .get<PurchaseOrder>(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<PurchaseOrder>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async getPurchaseOrderFilesDetail(id: number): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + `purchase-order-files/order/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http
      .get<PurchaseOrder>(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<PurchaseOrder>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async updatePurchaseOrders(id: number, user_id: number, purchaseOrder: any): Promise<any> {
    const api_url = `${environment.api_endpoint}purchase-orders/${id}?user_id=${user_id}`;
    try {
      const res = await this.http.put(api_url, purchaseOrder, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async warehouseReceivePurchaseOrders(id: number, user_id: number, purchaseOrder: any): Promise<any> {
    const api_url = `${environment.api_endpoint}purchase-orders/warehouse-receive/${id}?user_id=${user_id}`;
    try {
      const res = await this.http.put(api_url, purchaseOrder, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }
  async getPurchaseOrdersByDateRange(startDate: string, endDate: string): Promise<any> {
  const api_url = `${environment.api_endpoint}purchase-orders/by-date-range`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        params: {
          startDate,
          endDate
        },
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取指定日期範圍內的採購訂單失敗:', error);
    throw error;
  }
}

  async getProductList(): Promise<any> {
    const api_url = environment.api_endpoint + 'products';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async getPriceList(name: string): Promise<number[]> {
    const api_url = environment.api_endpoint + `products/get-prices/${name}`;
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async getOneProduct(id: number): Promise<any> {
    const api_url = environment.api_endpoint + `products/${id}`;
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        // console.log(res)
        return res;
      })
      .catch(error => error);
  }

  async getAllCategories(): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + 'category';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async getCategoryNames(): Promise<Category[]> {
    const api_url = environment.api_endpoint + 'products/category-names';
    const response = await this.http.get<Category[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch third level names');
    }
    return response;
  }

  async getAllCountries(): Promise<HttpResponse<Object> | undefined> {
    const api_url = environment.api_endpoint + 'countries/';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then((res?: HttpResponse<Object>) => {
        console.log(res);
        return res;
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  }

  async getCountriesNames(): Promise<Country[]> {
    const api_url = environment.api_endpoint + 'products/country-names';
    const response = await this.http.get<Country[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }

  async getBrandNames(): Promise<Brand[]> {
    const api_url = environment.api_endpoint + 'products/brand-names';
    const response = await this.http.get<Brand[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }

  async getBuyerNames(): Promise<string[]> {
    const api_url = environment.api_endpoint + 'users/names';
    const response = await this.http.get<string[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch buyer names');
    }
    return response;
  }

  async getUsersNames(): Promise<User[]> {
    const api_url = environment.api_endpoint + 'products/user-names';
    const response = await this.http.get<User[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }

  async getSupplierNames(): Promise<Supplier[]> {
    const api_url = environment.api_endpoint + 'products/supplier-names';
    const response = await this.http.get<Supplier[]>(api_url, { headers: this.headers }).toPromise();
    if (response === undefined) {
      throw new Error('Failed to fetch first level names');
    }
    return response;
  }

  //Methods about brands
  async getAllBrands(): Promise<any> {
    const api_url = environment.api_endpoint + 'brands';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async addBrand(data: any): Promise<any> {
    const api_url = environment.api_endpoint + 'brands';
    try {
      const res = await this.http.post(api_url, data, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async updateBrand(brandId: number, updatebrandDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}brands/${brandId}`;
    try {
      const res = await this.http.put(api_url, updatebrandDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async approveBrand(brandId: number, approvebrandDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}brands/approve/${brandId}`;
    try {
      const res = await this.http.put(api_url, approvebrandDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async uploadImage(brand_id: number, url: string, type: string) {
    const api_url = `${environment.api_endpoint}brand_images`;
    try {
      const res = await this.http.post(api_url, { brand_id, url, type }, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteImage(brandId: number, url: string) {
    const api_url = `${environment.api_endpoint}brand_images`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers, body: { brandId, url } }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async brandNameExists(name: string): Promise<any> {
    const url = `${environment.api_endpoint}brands/is_exist_brand_name/${name}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      console.log('Brand name exists:', response);
      return response;
    } catch (error) {
      console.error('Failed to get brand name', error);
      throw error;
    }
  }

  async addProductPriceChange(productPriceChange: ProductPriceChange): Promise<any> {
    const api_url = environment.api_endpoint + 'product-price-change';
    try {
      const res = await this.http.post(api_url, productPriceChange, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getAllProductPriceChanges(): Promise<any> {
    const api_url = environment.api_endpoint + 'product-price-change';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async updateProductPriceChange(productPriceChangeId: number, updateProductPriceChangeDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}product-price-change/${productPriceChangeId}`;
    try {
      const res = await this.http.put(api_url, updateProductPriceChangeDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async deleteProductPriceChanges(id: number) {
    const api_url = `${environment.api_endpoint}product-price-change/${id}`;
    try {
      const res = await this.http.delete(api_url, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  //Methods about Locations
  async getAllLocations(): Promise<any> {
    const api_url = environment.api_endpoint + 'locations';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async updateLocation(locationId: number, updateId: number, updateLocationDto: any): Promise<any> {
    const api_url = `${environment.api_endpoint}locations/${locationId}?location_id=${updateId}`;
    try {
      const res = await this.http.put(api_url, updateLocationDto, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async addLocation(location: Location): Promise<any> {
    const api_url = environment.api_endpoint + 'locations';
    try {
      const res = await this.http.post(api_url, location, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async disableLocation(location: Location): Promise<any> {
    const api_url = environment.api_endpoint + `locations/disable/${location.id}`;
    try {
      const res = await this.http.patch(api_url, {}, { headers: this.headers }).toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  // Methods about Inventory_error
  async getAllInventoryError(): Promise<any> {
    const api_url = environment.api_endpoint + 'inventory_error';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, { headers: this.headers, params: params, observe: 'response', responseType: 'json', withCredentials: true })
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(error => error);
  }

  async updateSheet() {
    const apiUrl = `${environment.api_endpoint}products/update-template`;
    try {
      const response = await this.http.get(apiUrl, { headers: this.headers, responseType: 'blob' }).toPromise();
      if (!response) {
        throw new Error('Failed to retrieve the file.');
      }
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Import_Product_Template.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('下载模板时出错:', error);
      alert('下载模板失败，请稍后再试。');
    }
  }

  async reviewInventoryError(ids: number[], status: string, user_id: number): Promise<any> {
    const api_url = environment.api_endpoint + 'inventory_error/review';
    try {
      const res = await this.http
        .put(
          api_url,
          { ids, status, user_id },
          {
            headers: this.headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: true,
          }
        )
        .toPromise();
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getInventoryDetail(collection_id: string): Promise<any> {
    const api_url = environment.api_endpoint + `shopify/inventory-collection/${collection_id}`;
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }
  async findInventoriesByIds(ids: number[]): Promise<any> {
  const api_url = `${environment.api_endpoint}inventories/list`;
  try {
    const response = await this.http.post(
      api_url, 
      { ids }, 
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('获取库存列表失败:', error);
    throw error;
  }
}

async findAllInventories(): Promise<any> {
  const api_url = `${environment.api_endpoint}inventories`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取所有庫存數據失敗:', error);
    throw error;
  }
}

async findInventoriesSimple(): Promise<any> {
    const api_url = `${environment.api_endpoint}inventories/simple`;
    try {
      const response = await this.http.get(
        api_url,
        {
          headers: this.headers,
          withCredentials: true
        }
      ).toPromise();
      return response;
    } catch (error) {
      console.error('獲取基礎庫存數據失敗:', error);
      throw error;
    }
  }
  
  async findInventoriesByFilters(productId?: number, locationId?: number): Promise<any> {
    const api_url = `${environment.api_endpoint}inventories/search`;
     // 构建查询参数
    let params = new HttpParams();
    if (productId) {
      params = params.append('productId', productId.toString());
    }
    if (locationId) {
      params = params.append('locationId', locationId.toString());
    }
    try {
      const response = await this.http.get(
        api_url,
        {
          headers: this.headers,
          params: params,
          withCredentials: true
        }
      ).toPromise();
      return response;
    } catch (error) {
      console.error('按條件查詢庫存數據失敗:', error);
      throw error;
    }
  }
  
  async getInventoryWithCategories(): Promise<any> {
  const api_url = `${environment.api_endpoint}inventories/with-categories`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取帶分類信息的庫存數據失敗:', error);
    throw error;
  }
}


  async getAllOrdersLastSevenDays(location_id: number): Promise<any> {
    const api_url = environment.api_endpoint + `shopify/orders-last-seven-days/${location_id}`;
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async getOrderDetail(): Promise<any> {
    const api_url = environment.api_endpoint + 'shopify/orders';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }

  async getRecentEightDaysSales(productIds: number[], locationId: number): Promise<any> {
  const api_url = `${environment.api_endpoint}order_item_snapshots/recent-sales`;
  try {
    const response = await this.http.post(
      api_url, 
      { 
        productIds, 
        locationId 
      }, 
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取最近8天銷售數據失敗:', error);
    throw error;
  }
}


  async getAllWarehouseTransfer(): Promise<any> {
    const api_url = environment.api_endpoint + 'warehouse-transfer';
    var params = new HttpParams().appendAll({});
    return this.http
      .get(api_url, {
        headers: this.headers,
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(error => error);
  }
  async getBetweenWarehouseTransferData(startDate: string, endDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}warehouse-transfer/between-dates/${startDate}/${endDate}`;

    try {
      const response = await this.http
        .get(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('獲取指定日期範圍的倉庫轉移數據失敗:', error);
      throw error;
    }
  }

  async getWarehouseTransferDetail(id: number): Promise<any> {
    const api_url = `${environment.api_endpoint}warehouse-transfer/${id}/detail`;
    const params = new HttpParams();

    try {
      const res = await this.http
        .get(api_url, {
          headers: this.headers,
          params: params,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();

      console.log('Warehouse transfer detail:', res);
      return res;
    } catch (error) {
      console.error('Error fetching warehouse transfer detail:', error);
      throw error;
    }
  }
  
  async getWarehouseLocationMapping(): Promise<any> {
  const api_url = `${environment.api_endpoint}warehouse-transfer-product/warehouse-location`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取倉庫位置映射關係失敗:', error);
    throw error;
  }
}

  async addWarehouseTransfer(transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer`;

    try {
      const response = await this.http.post(url, transfer, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add transfer', error);
      throw error;
    }
  }

  async addWarehouseTransferByFranchise(transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/franchise`;

    try {
      const response = await this.http.post(url, transfer, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add transfer', error);
      throw error;
    }
  }

  async updateWarehouseTransfer(id: number, transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/${id}`;

    try {
      const response = await this.http.put(url, transfer, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add transfer', error);
      throw error;
    }
  }
  async mergeWarehouseTransfer(id: number, transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/merge/${id}`;

    try {
      const response = await this.http.put(url, transfer, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to merge transfer', error);
      throw error;
    }
  }

  async updateWarehouseTransferDate(id: number, transfer_date: Date, updated_by: number): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/${id}/transfer-date`;
    const body = { transfer_date, updated_by };

    try {
      const response = await this.http
        .put(url, body, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('Failed to update transfer date', error);
      throw error;
    }
  }

  async approveWarehouseTransfer(id: number, transfer: any): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/approve/${id}`;

    try {
      const response = await this.http.put(url, transfer, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to add transfer', error);
      throw error;
    }
  }

  async deleteWarehouseTransfer(id: number): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/${id}`;

    try {
      const response = await this.http.delete(url, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to delete transfer', error);
      throw error;
    }
  }

  async getWarehouseReservedInventory(shopify_id: string): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/reserved-inventory/${shopify_id}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get reserved inventory', error);
      throw error;
    }
  }
  
  async getWarehouseReservedInventoryBatch(shopifyIds: string[]): Promise<any> {
  const url = `${environment.api_endpoint}warehouse-transfer/reserved-inventory`;

  try {
    const response = await this.http.post(
      url, 
      { shopifyIds }, 
      { headers: this.headers }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('批量獲取占用庫存失敗', error);
    throw error;
  }
}

  async syncWarehouseTransferToShopify(id: number): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/sync-shopify/${id}`;

    try {
      const response = await this.http.post(url, {}, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async reverseSyncWarehouseTransferToShopify(id: number): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/sync-shopify-reverse/${id}`;

    try {
      const response = await this.http.post(url, {}, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async batchSyncWarehouseTransferToShopify(ids: number[]): Promise<any> {
    const url = `${environment.api_endpoint}warehouse-transfer/batch-sync-shopify`;

    try {
      const response = await this.http.post(url, { ids }, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllStockTakes(): Promise<StockTake[]> {
    const api_url = environment.api_endpoint + 'stock-take';
    var params = new HttpParams().appendAll({});
    return this.http
      .get<StockTake[]>(api_url, { headers: this.headers, params: params, withCredentials: true })
      .toPromise()
      .then((res: StockTake[] | undefined) => {
        console.log(res);
        return res || [];
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  async getStockTakeDetail(id: number): Promise<any> {
    const api_url = `${environment.api_endpoint}stock-take/${id}`;
    const params = new HttpParams();

    try {
      const res = await this.http
        .get(api_url, {
          headers: this.headers,
          params: params,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();

      console.log('Warehouse transfer detail:', res);
      return res;
    } catch (error) {
      console.error('Error fetching warehouse transfer detail:', error);
      throw error;
    }
  }

  async syncStockTakeToShopify(id: number): Promise<any> {
    const url = `${environment.api_endpoint}stock-take/sync-shopify/${id}`;

    try {
      const response = await this.http.post(url, {}, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync stock take to Shopify', error);
      throw error;
    }
  }

  async getProductInventory(product_id: string): Promise<any> {
    const url = `${environment.api_endpoint}shopify/inventories/${product_id}`;

    try {
      const response = await this.http.get(url, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync stock take to Shopify', error);
      throw error;
    }
  }
    async createShopifyProductSync(sync: CreateShopifyProductSyncDto): Promise<ShopifyProductSync> {
    const api_url = `${environment.api_endpoint}shopify-product-sync`;
    try {
      const response = await this.http.post<ShopifyProductSync>(
        api_url, 
        sync, 
        {
          headers: this.headers,
          withCredentials: true
        }
      ).toPromise();
      
      if (!response) {
        throw new Error('No response from server');
      }
      
      return response;
    } catch (error) {
      console.error('創建 Shopify 產品同步失敗:', error);
      throw error;
    }
  }
  
    async getLatestShopifySync(): Promise<ShopifyProductSync> {
    const api_url = `${environment.api_endpoint}shopify-product-sync/latest`;
    try {
      const response = await this.http.get<ShopifyProductSync>(
        api_url,
        {
          headers: this.headers,
          withCredentials: true
        }
      ).toPromise();
      
      if (!response) {
        throw new Error('No response from server');
      }
      
      return response;
    } catch (error) {
      console.error('獲取最新同步記錄失敗:', error);
      throw error;
    }
  }


  async syncProductsFromShopify(): Promise<any> {
    const url = `${environment.api_endpoint}shopify/sync-shopify-products`;

    try {
      const response = await this.http.post(url, {}, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync products from Shopify', error);
      throw error;
    }
  }

  async syncOrdersFromShopify(): Promise<any> {
    const url = `${environment.api_endpoint}shopify/sync-shopify-orders`;

    try {
      const response = await this.http.post(url, {}, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync orders from Shopify', error);
      throw error;
    }
  }

  async syncProductToShopify(product_id: number): Promise<any> {
    const url = `${environment.api_endpoint}shopify/sync-product/${product_id}`;

    try {
      const response = await this.http.post(url, {}, { headers: this.headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Failed to sync products from Shopify', error);
      throw error;
    }
  }

  async getLastSevenDaysTransferData(startDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}warehouse-transfer/last-seven-days/${startDate}`;

    try {
      const response = await this.http
        .get(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get last seven days transfer data', error);
      throw error;
    }
  }

  async getBetweenTransferData(startDate: string, endDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}warehouse-transfer/between-dates/${startDate}/${endDate}`;

    try {
      const response = await this.http
        .get(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get between days transfer data', error);
      throw error;
    }
  }

  async getBetweenInventoryErrors(startDate: string, endDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}inventory_error/between-dates/${startDate}/${endDate}`;

    try {
      const response = await this.http
        .get(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('获取指定日期范围内的库存错误失败:', error);
      throw error;
    }
  }
  async deleteBrand(id: number): Promise<any> {
    const api_url = environment.api_endpoint + `brands/${id}`;
    try {
      const res = await this.http
        .delete(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();

      return res;
    } catch (error: any) {
      // 打印完整错误对象以便调试
      console.error('Delete brand error:', JSON.stringify(error, null, 2));

      // 尝试获取 MySQL 原始错误信息
      const mysqlError = error.error?.sqlMessage || error.error?.message || error.error?.error?.message || error.message;

      console.log('MySQL error:', mysqlError);

      // 检查所有可能的错误信息位置
      if (
        mysqlError &&
        (mysqlError.includes('Cannot delete or update a parent row') ||
          mysqlError.includes('foreign key constraint fails') ||
          mysqlError.includes('FK_2eb5ce4324613b4b457c364f4a2'))
      ) {
        error.customMessage = '該品牌下還有關聯產品，無法刪除';
      } else {
        error.customMessage = '刪除品牌失敗，請確認該品牌下沒有關聯產品';
      }
      throw error;
    }
  }
  async getAllOrders(): Promise<any> {
    const api_url = environment.api_endpoint + 'orders';
    return this.http
      .get(api_url, {
        headers: this.headers,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        console.log('Orders response:', res);
        return res;
      })
      .catch(error => {
        console.error('Error fetching orders:', error);
        throw error;
      });
  }

  // 获取单个订单详情
  async getOrderById(id: number): Promise<any> {
    const api_url = `${environment.api_endpoint}orders/${id}`;
    return this.http
      .get(api_url, {
        headers: this.headers,
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise()
      .then(res => {
        // console.log('Order detail response:', res);
        return res;
      })
      .catch(error => {
        console.error(`Error fetching order ${id}:`, error);
        throw error;
      });
  }

  async getOrdersBetweenDates(startDate: string, endDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}orders/between-dates/${startDate}/${endDate}`;

    try {
      const response = await this.http
        .get(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('获取指定日期范围内的订单失败:', error);
      throw error;
    }
  }
  
    async getOrdersBetweenDatesSimple(startDate: string, endDate: string): Promise<any> {
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    const api_url = `${environment.api_endpoint}orders/between-dates-simple/${encodedStartDate}/${encodedEndDate}`;

    try {
      const response = await this.http
        .get(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('獲取指定日期範圍內的訂單簡化數據失敗:', error);
      throw error;
    }
  }

  async getOrderItemsBetweenDates(startDate: string, endDate: string): Promise<any> {
    const api_url = `${environment.api_endpoint}order-items/between-dates/${startDate}/${endDate}`;

    try {
      const response = await this.http
        .get(api_url, {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('获取指定日期范围内的订单失败:', error);
      throw error;
    }
  }
  async getOrderItemsByProductIds(productIds: number[]): Promise<any> {
  const api_url = `${environment.api_endpoint}order-items/by-products`;
  
  try {
    // 将产品ID数组转换为逗号分隔的字符串
    const productIdsString = productIds.join(',');
    
    const response = await this.http
      .get(api_url, {
        headers: this.headers,
        params: { productIds: productIdsString },
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      })
      .toPromise();
      
    return response;
  } catch (error) {
    console.error('按商品ID獲取訂單項目失敗:', error);
    throw error;
  }
}

async getOrderItemsByProductIdsFast(productIds: number[]): Promise<any> {
  const api_url = `${environment.api_endpoint}order-items/by-products-fast`;
  try {
   
    const productIdsString = productIds.join(',');
    
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        params: { productIds: productIdsString },
        withCredentials: true
      }
    ).toPromise();
    
    return response;
  } catch (error) {
    console.error('快速獲取訂單項目失敗:', error);
    throw error;
  }
}
async getOrderItemsByProductIdsBasic(productIds: number[]): Promise<any> {
  const api_url = `${environment.api_endpoint}order-items/by-products-basic`;
  try {
    // 将产品ID数组转换为逗号分隔的字符串
    const productIdsString = productIds.join(',');
    
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        params: { productIds: productIdsString },
        withCredentials: true
      }
    ).toPromise();
    
    return response;
  } catch (error) {
    console.error('獲取基礎訂單項目失敗:', error);
    throw error;
  }
}

async checkRecentOrders(productIds: number[]): Promise<{ hasOrders: { [key: number]: boolean } }> {
  const api_url = `${environment.api_endpoint}order-items/check-recent-orders`;
  
  try {
    const response = await this.http.post<{ hasOrders: { [key: number]: boolean } }>(
      api_url,
      { productIds },
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    
    if (!response) {
      throw new Error('未收到服務器響應');
    }
    
    return response;
  } catch (error) {
    console.error('檢查最近訂單失敗:', error);
    throw error;
  }
}

async getOrderItemsByIdsWithDateRange(
  productIds: number[],
  startDate: string,
  endDate: string
): Promise<any> {
  const api_url = `${environment.api_endpoint}order-items/ids-with-date-range`;
  
  try {
    const response = await this.http.post(
      api_url,
      {
        productIds,
        startDate,
        endDate
      },
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    
    return response;
  } catch (error) {
    console.error('獲取指定日期範圍內的訂單項目失敗:', error);
    throw error;
  }
}


  async getProductsInventories(shopifyIds: string[]): Promise<any> {
    const url = `${environment.api_endpoint}shopify/inventories`;

    try {
      const response = await this.http
        .post(
          url,
          { shopifyIds },
          {
            headers: this.headers,
          }
        )
        .toPromise();
      return response;
    } catch (error) {
      console.error('Failed to get products inventories:', error);
      throw error;
    }
  }

  async findLatestPurchaseOrdersByProductIds(shopifyIds: string[]): Promise<any> {
    const url = `${environment.api_endpoint}purchase-orders/latest-by-shopify`;

    try {
      const response = await this.http
        .post(
          url,
          { shopifyIds },
          {
            headers: this.headers,
            observe: 'response',
            responseType: 'json',
            withCredentials: true,
          }
        )
        .toPromise();

      return response?.body;
    } catch (error) {
      console.error('獲取最近採購記錄失敗:', error);
      throw error;
    }
  }
  
async findLatestPurchaseOrderByProductIds(productIds: number[]): Promise<any> {
  const url = `${environment.api_endpoint}purchase-orders/latest-by-products`;

  try {
    const response = await this.http
      .post(
        url,
        { productIds },
        {
          headers: this.headers,
          observe: 'response',
          responseType: 'json',
          withCredentials: true,
        }
      )
      .toPromise();

    return response?.body;
  } catch (error) {
    console.error('批量獲取產品最近採購記錄失敗:', error);
    throw error;
  }
}

async getProductIdsWithPurchaseDate(): Promise<any> {
  const api_url = `${environment.api_endpoint}purchase-order-products/product-ids-with-purchase-date`;
  try {
    const response = await this.http.get(api_url, {
      headers: this.headers,
      withCredentials: true
    }).toPromise();
    return response;
  } catch (error) {
    console.error('獲取產品ID與採購日期失敗:', error);
    throw error;
  }
}

  async deleteStockTakes(ids: number[]): Promise<void> {
    try {
      const deletePromises = ids.map(async id => {
        try {
          await this.http
            .delete(`${environment.api_endpoint}stock-take/${id}`, {
              headers: this.headers,
              withCredentials: true,
            })
            .toPromise();
        } catch (error: any) {
          if (error.status === 500) {
            throw new Error(`刪除ID ${id} 失敗: 伺服器內部錯誤`);
          }
          throw new Error(`刪除ID ${id} 失敗: ${error.error?.message || '未知錯誤'}`);
        }
      });

      await Promise.all(deletePromises);
    } catch (error) {
      console.error('刪除盤點記錄失敗:', error);
      throw error;
    }
  }

  async mergeStockTakes(ids: number[]): Promise<any> {
    const url = `${environment.api_endpoint}stock-take/merge`;

    try {
      const response = await this.http
        .post(url, ids, {
          headers: this.headers,
          withCredentials: true,
        })
        .toPromise();
      return response;
    } catch (error) {
      console.error('Failed to merge stock takes:', error);
      throw error;
    }
  }

  async markStockTakesAsSynced(ids: number[]): Promise<any> {
    const url = `${environment.api_endpoint}stock-take/batch-sync-status`;
    try {
      if (!ids || ids.length === 0) {
        throw new Error('No stock take IDs provided');
      }
      // Convert numbers to strings and ensure they are valid numbers
      const validIds = ids.map(id => id.toString()).filter(id => !isNaN(Number(id)));
      if (validIds.length !== ids.length) {
        throw new Error('Invalid IDs detected: all IDs must be valid numbers');
      }
      const response = await firstValueFrom(
        this.http.put(
          url,
          { ids: validIds },
          {
            headers: this.headers,
            withCredentials: true,
          }
        )
      );
      return response;
    } catch (error: any) {
      console.error('Failed to mark stock takes as synced:', error);
      if (error.status === 400) {
        throw new Error(`Invalid request: ${error.error?.message || 'Please check the stock take IDs'}`);
      }
      throw error;
    }
  }
  async getLocationDailySalesByDateRange(startDate: string, endDate: string): Promise<any> {
  const api_url = `${environment.api_endpoint}location-daily-sales/date-range`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        params: {
          startDate,
          endDate
        },
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取位置每日銷售數據失敗:', error);
    throw error;
  }
}

async getAllProductsWithDaysSinceLastSale(): Promise<any> {
  const api_url = `${environment.api_endpoint}order_item_snapshots/all-last-sale`;
  try {
    const response = await this.http.get(
      api_url,
      {
        headers: this.headers,
        withCredentials: true
      }
    ).toPromise();
    return response;
  } catch (error) {
    console.error('獲取所有產品最後銷售日期失敗:', error);
    throw error;
  }
}

}
