// src/app/auth.service.ts
import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { PermissionConfig } from '../models/permission-config';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private user: any;

  isAdmin: boolean = false;
  isBuyer: boolean = false;
  isTesting: boolean = false;
  isSupplier: boolean = false;
  isCostPermissionAllowed: boolean = false;
  isAnalyticSectionAllowed: boolean = false;
  isProductSectionAllowed: boolean = false;
  isEditProductAllowed: boolean = false;
  isViewAllProductsAndSuppliersAllowed: boolean = false;
  isPurchaseOrderSectionAllowed: boolean = false;
  isEditPurchaseOrderAllowed: boolean = false;
  isPaymentPurchaseOrderAllowed: boolean = false;
  isInventorySectionAllowed: boolean = false;
  isInventoryAnalysisSectionAllowed: boolean = false;
  isWarehouseProductSearchAllowed: boolean = false;
  isUserSectionAllowed: boolean = false;
  isLocationSectionAllowed: boolean = false;
  isUserActivitySectionAllowed: boolean = false;
  isEditLocationSectionAllowed: boolean = false;
  isFranchiseSectionAllowed: boolean = false;
  isOperationSectionAllowed: boolean = false;
  isViewAllPurchaseOrdersAllowed: boolean = false;
  isSupplierAnalysisAllowed: boolean = false;
  isViewAllSuppliersAllowed: boolean = false;
  adminPermissionConfig: PermissionConfig = {
    allowedRoles: ['管理員']
  };
  buyerPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購'],
    allowedRoles: ['採購員', '採購主管']
  };
  costPermissionConfig: PermissionConfig = {
    allowedRoles: ['管理員']
  };
  operationPermissionConfig: PermissionConfig = {
    allowedRoles: ['管理員']
  };
  productSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購','供應商'],
    allowedRoles: ['管理員']
  };
  editProductPermissionConfig: PermissionConfig = {
    allowedRoles: ["採購主管"]
  };
  viewAllProductsAndSuppliersPermissionConfig: PermissionConfig = {
    allowedRoles: ["採購主管"]
  };
  purchaseOrderSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購'],
    allowedRoles: ['管理員']
  };
  editPurchaseOrderPermissionConfig: PermissionConfig = {
    
    allowedRoles: ['管理員',"採購主管"]
  };
  paymentPurchaseOrderPermissionConfig: PermissionConfig = {
    allowedRoles: ['管理員']
  };
  inventorySectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['倉庫', '營運'],
    allowedRoles: ['管理員']
  };
  inventoryAnalysisSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['倉庫', '營運', '採購'],
    allowedRoles: ['管理員']
  };
  warehouseProductSearchSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['倉庫', '營運'],
    allowedRoles: ['管理員']
  };
  userSectionPermissionConfig: PermissionConfig = {
    allowedRoles: ['管理員']
  };
  analyticSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購'],
    allowedRoles: ['倉務主管', '管理員'],
  };
  locationSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購', '倉庫'],
    allowedRoles: ['管理員']
  };
  userActivitySectionPermissionConfig: PermissionConfig = {};
  editLocationSectionPermissionConfig: PermissionConfig = {};
  franchiseSectionPermissionConfig: PermissionConfig = {
    allowedDepartments: ['加盟'],
    allowedRoles: ['加盟商','管理員']
  };
  viewAllPurchaseOrdersConfig: PermissionConfig = {
    allowedRoles: ['管理員', '採購主管']
  };

  showUserSelectorConfig: PermissionConfig = {
    allowedRoles: ['管理員']
  };

  purchaseTargetMenuConfig: PermissionConfig = {
    allowedDepartments: ['採購', '門店'],
    allowedRoles: ['管理員']
  };

  purchaseAnalyticsMenuConfig: PermissionConfig = {
    allowedRoles: ['管理員', '採購主管']
  };

  supplierAnalysisPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購'],
    allowedRoles: ['管理員']
  };
  viewAllSuppliersPermissionConfig: PermissionConfig = {
    
  };

  salesAnalysisPermissionConfig: PermissionConfig = {
    allowedDepartments: ['採購', '門店'],
    allowedRoles: ['管理員']
  };

  supplierPermissionConfig: PermissionConfig = {
    allowedRoles: ['供應商']
  };

  constructor(private router: Router, private location: Location) {
  }

  setUser(user: any) {
    this.user = user;
    this.loadPermissions();
  }

  getUser() {
    return this.user;
  }

  loadPermissions() {
    const currentUrl = window.location.href;
    this.isTesting = currentUrl.includes('test') || currentUrl.includes('xt');
    this.isSupplier = this.isAllowed(this.supplierPermissionConfig);
    this.isCostPermissionAllowed = this.isAllowed(this.costPermissionConfig);
    this.isProductSectionAllowed = this.isAllowed(this.productSectionPermissionConfig);
    this.isEditProductAllowed = this.isAllowed(this.editProductPermissionConfig);
    this.isViewAllProductsAndSuppliersAllowed = this.isAllowed(this.viewAllProductsAndSuppliersPermissionConfig);
    this.isPurchaseOrderSectionAllowed = this.isAllowed(this.purchaseOrderSectionPermissionConfig);
    this.isEditPurchaseOrderAllowed = this.isAllowed(this.editPurchaseOrderPermissionConfig);
    this.isPaymentPurchaseOrderAllowed = this.isAllowed(this.paymentPurchaseOrderPermissionConfig);
    this.isInventorySectionAllowed = this.isAllowed(this.inventorySectionPermissionConfig);
    this.isInventoryAnalysisSectionAllowed = this.isAllowed(this.inventoryAnalysisSectionPermissionConfig);
    this.isWarehouseProductSearchAllowed = this.isAllowed(this.warehouseProductSearchSectionPermissionConfig);
    this.isAnalyticSectionAllowed = this.isAllowed(this.analyticSectionPermissionConfig);
    this.isUserSectionAllowed = this.isAllowed(this.userSectionPermissionConfig);
    this.isLocationSectionAllowed = this.isAllowed(this.locationSectionPermissionConfig);
    this.isUserActivitySectionAllowed = this.isAllowed(this.userActivitySectionPermissionConfig);
    this.isEditLocationSectionAllowed = this.isAllowed(this.editLocationSectionPermissionConfig);
    this.isFranchiseSectionAllowed = this.isAllowed(this.franchiseSectionPermissionConfig);
    this.isOperationSectionAllowed = this.isAllowed(this.operationPermissionConfig);
    this.isViewAllPurchaseOrdersAllowed = this.isAllowed(this.viewAllPurchaseOrdersConfig);
    this.isAdmin = this.isAllowed(this.adminPermissionConfig);
    this.isBuyer = this.isAllowed(this.buyerPermissionConfig);
    this.isSupplierAnalysisAllowed = this.isAllowed(this.supplierAnalysisPermissionConfig);
    this.isViewAllSuppliersAllowed = this.isAllowed(this.viewAllSuppliersPermissionConfig);
  }

  isAllowed(...permissions: PermissionConfig[]): boolean {
    return permissions.some(permission => {
      const isRoleAllowed =
        ((permission.allowedRoles !== undefined) &&
        permission.allowedRoles.includes(this.user.role_name)) ||
        this.user.role_name === '管理員';

      const isDepartmentAllowed =
        (permission.allowedDepartments !== undefined) &&
        permission.allowedDepartments.includes(this.user.department);

      return isRoleAllowed || isDepartmentAllowed;
    });
  }

  isUserAllowed(user: User, ...permissions: PermissionConfig[]): boolean {
    return permissions.some(permission => {
      const isRoleAllowed =
        ((permission.allowedRoles !== undefined) &&
        permission.allowedRoles.includes(user.role)) ||
        user.role === '管理員';

      const isDepartmentAllowed =
        (permission.allowedDepartments !== undefined) &&
        permission.allowedDepartments.includes(user.department);

      return isRoleAllowed || isDepartmentAllowed;
    });
  }

  check(isAllowed: boolean) {
    if (!isAllowed) {
      this.router.navigate(['/forbidden']);
    }
  }
  

  // isAdmin(): boolean {
  //   return this.user.role_name === '管理員';
  // }

  canViewAllPurchaseOrders(): boolean {
    return this.isAdmin || this.user.role_name === '採購主管';
  }

  canShowUserSelector(): boolean {
    return this.isAllowed(this.showUserSelectorConfig);
  }

  canShowPurchaseTargetMenu(): boolean {
    return this.isAllowed(this.purchaseTargetMenuConfig);
  }

  canShowPurchaseAnalyticsMenu(): boolean {
    return this.isAllowed(this.purchaseAnalyticsMenuConfig);
  }

  isUserBuyer(user: User): boolean {
    return this.isUserAllowed(user, this.buyerPermissionConfig);
  }

  canShowSalesAnalysis(): boolean {
    return this.isAllowed(this.salesAnalysisPermissionConfig);
  }

  // 新增方法：判斷用戶是否僅為供應商角色
  isSupplierOnly(): boolean {
    return this.isSupplier && this.user.role_name === '供應商' ;
  }
}
