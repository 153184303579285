import { Country } from "./country";
import { ProductFile } from "./product-file";

export interface LatestPurchase {
  supplier_name: string | null;
  supplier_name_zh: string | null;
  supplier_name_en: string | null;
  purchase_date: string | null;
  currency: string | null;
  quantity: number | null;
  accepted_quantity: number | null;
  cost: number | null;
}

interface Product {
    id: number;
    name: string;
    description: string;
    status: string;
    handle: string;
    barcode: string;
    cost: number;
    price: number;
    compare_at_price: number;
    content: number;
    content_unit: string;
    category: Category|null;
    category_id: number|null;
    brand: Brand|null;
    brand_id: number|null;
    country: Country|null;
    country_id: number|null;


    country_name: string|null;
    buyer: { id: number, name: string } | null;
    buyer_id: number | null;
    buyer_name: string | null;
    storage_condition: string;
    shelf_life: number;
    shelf_life_unit: string;
    has_long_term_supply: string;
    product_files: ProductFile[];
    created_by: { id: number, name: string };
    created_at: string;
    updated_by: { id: number, name: string };
    updated_at: string;
    // 規格信息合并
    min_spec_quantity: number;
    min_spec_unit: string;
    min_spec_barcode: string;
    mid_spec_quantity: number;
    mid_spec_unit: string;
    mid_spec_barcode: string;
    box_spec_quantity: number;
    box_spec_unit: string;
    box_spec_barcode: string;
    purchase_spec: string;
    wholesale_spec: string;
    distribution_spec: string;
    sale_spec: string;
    shopify_id: string;
    gross_margin: number;
    average_daily_transfer: number;
    total_actual_transfer_quantity: number;
    total_sales_quantity: number;
    last_seven_days_sales: number;
    inventory_quantity?: number;
    inventory_details?: Array<{
        locationId: string;
        available: number;
    }>;
    latest_purchase?: LatestPurchase;
    total_inventory?: number;
    expiry_date_type: 'best_before' | 'expiry';
    expiry_date: Date | null;
    related_product_id: number;
    related_product_coefficient: number;

}



interface Brand {
  id: number;
  name: string;
}

interface Category {
  id: number;
  first_level_name: string;
  second_level_name: string;
  third_level_name: string;
}

interface Supplier {
  id: number;
  name_en: string;
}

export { Supplier,Brand,Category,Product };
