<nz-page-header
  class="site-page-header"
  [nzTitle]="'產品列表'"
  style="height: 44px; padding-bottom: 54px; padding-top: 0; padding-left: 4px; padding-right: 0px"
  (dblclick)="onHeaderDoubleClick()"
>
  <nz-page-header-subtitle *ngIf="latestSyncTime" style="font-size: 12px; font-style: italic; margin-top: 8px;">
    更新時間: {{ latestSyncTime | date:'yyyy-MM-dd HH:mm:ss' }} HKT
  </nz-page-header-subtitle>
  <nz-page-header-extra>
    <!-- <button
      nz-button
      nzType="primary"
      style="margin-right: 16px; background-color: #52c41a; border-color: #52c41a"
      (click)="syncToShopify()"
      *ngIf="showSyncButton"
    >
      同步Shopify產品
    </button> -->
    <button
      nz-button
      nzType="primary"
      style="margin-right: 16px; background-color: #52c41a; border-color: #52c41a"
      (click)="syncOrdersToShopify()"
      *ngIf="showSyncButton"
    >
      同步Shopify訂單
    </button>
    <button nz-button nzType="primary" nzMatchRouter routerLink="/products/import" style="margin-right: 16px" *ngIf="permission.isViewAllProductsAndSuppliersAllowed">
      導入產品
    </button>
    <button nz-button nzType="primary" nzMatchRouter routerLink="/products/add" *ngIf="permission.isViewAllProductsAndSuppliersAllowed">新增產品</button>
  </nz-page-header-extra>
</nz-page-header>

<nz-card>
  <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24 }">
    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <div nz-row>
        
        <input
          name="product_name"
          nz-input
          placeholder="搜索產品名稱"
          [(ngModel)]="searchParam.product_name"
          [nzAutocomplete]="product_name"
          (ngModelChange)="onSearch()"
        />
        <nz-autocomplete #product_name>
          <nz-auto-option *ngFor="let option of getFilteredProductNames(searchParam.product_name)" [nzValue]="option">
            {{ option }}
          </nz-auto-option>
        </nz-autocomplete>
      </div>
    </div>

    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <div nz-row>
        
        <nz-select
          name="supplier"
          nz-input
          nzPlaceHolder="搜索供應商"
          [(ngModel)]="searchParam.supplier_name"
          [nzMode]="'multiple'"
          (ngModelChange)="onSearch()"
        >
          <nz-option
            *ngFor="let supplier of supplierFilterOptions"
            [nzLabel]="supplier"
            [nzValue]="supplier"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
      <div nz-row>
        
        <input
          name="buyer_id"
          nz-input
          placeholder="搜索採購員"
          [(ngModel)]="searchParam.buyer_id"
          [nzAutocomplete]="buyer_id"
          (ngModelChange)="onSearch()"
        />
        <nz-autocomplete
          #buyer_id
          [nzBackfill]="true"
          (selectionChange)="onSearch()"
          [nzDefaultActiveFirstOption]="true"
        >
          <nz-auto-option *ngFor="let option of buyerFilterOptions" [nzValue]="option" [nzLabel]="getBuyerName(option)">
            {{ getBuyerName(option) }}
          </nz-auto-option>
        </nz-autocomplete>
      </div>
    </div>
     <div class="expand-button-container">
      <button nz-button nzType="link" class="expand-button" (click)="toggleFilters()">
        {{ showMoreFilters ? '收起' : '更多選項' }}
        <span nz-icon [nzType]="showMoreFilters ? 'up' : 'down'"></span>
      </button>
    </div>

    <ng-container *ngIf="showMoreFilters">
      <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
        <div nz-row>
          
          <input
            name="barcode"
            nz-input
            placeHolder="搜索條碼"
            [(ngModel)]="searchParam.barcode"
            [nzAutocomplete]="barcode"
            (ngModelChange)="onSearch()"
          />
          <nz-autocomplete #barcode>
            <nz-auto-option *ngFor="let option of getFilteredBarcode(searchParam.barcode)" [nzValue]="option">
              {{ option }}
            </nz-auto-option>
          </nz-autocomplete>
        </div>
      </div>

      <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
        <div nz-row>

          <nz-select
            name="brand"
            nz-input
            nzPlaceHolder="搜索品牌"
            [(ngModel)]="searchParam.brand_list"
            [nzMode]="'multiple'"
            (ngModelChange)="onSearch()"
          >
            <nz-option *ngFor="let option of brandListFilterOptions" [nzLabel]="option" [nzValue]="option"></nz-option>
          </nz-select>
        </div>
      </div>

      <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
        <div nz-row>
          
          <nz-select
            name="country"
            nz-input
            nzPlaceHolder="搜索產地"
            [(ngModel)]="searchParam.country"
            [nzMode]="'multiple'"
            (ngModelChange)="onSearch()"
          >
            <nz-option *ngFor="let country of countryFilterOptions" [nzLabel]="country" [nzValue]="country"></nz-option>
          </nz-select>
        </div>
      </div>

      <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
        <div nz-row>

          <nz-select
            name="first"
            nz-input
            nzPlaceHolder="搜索一級分類"
            [(ngModel)]="searchParam.first_level_name"
            [nzMode]="'multiple'"
            (ngModelChange)="onFirstLevelChange()"
            [nzDisabled]="isFirstLevelLocked"
          >
            <nz-option 
              *ngFor="let first of firstLevelFilterOptions" 
              [nzLabel]="first" 
              [nzValue]="first"
            ></nz-option>
          </nz-select>
        </div>
      </div>

      <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
        <div nz-row>

          <nz-select
            name="second"
            nz-input
            nzPlaceHolder="搜索二級分類"
            [(ngModel)]="searchParam.second_level_name"
            [nzMode]="'multiple'"
            (ngModelChange)="onSecondLevelChange()"
          >
            <nz-option 
              *ngFor="let second of secondLevelFilterOptions" 
              [nzLabel]="second" 
              [nzValue]="second"
            ></nz-option>
          </nz-select>
        </div>
      </div>

      <div nz-col [nzSpan]="24" style="margin-top: 16px;">
        <div nz-row [nzGutter]="24">
          <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
            <div nz-row>
              <div style="width: 100%;">最近採購日期：</div>
              <div style="width: 100%; margin-top: 8px;">
                <nz-range-picker
                  [(ngModel)]="searchParam.purchase_date_range"
                  (ngModelChange)="onDateRangeChange($event)"
                  [nzPlaceHolder]="['開始日期', '結束日期']"
                  style="width: 100%;"
                ></nz-range-picker>
              </div>
            </div>
          </div>

          <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
            <div nz-row>
              <div style="width: 100%;">到期日：</div>
              <div style="width: 100%; margin-top: 8px;">
                <nz-range-picker
                  [(ngModel)]="searchParam.expiry_date_range"
                  (ngModelChange)="onExpiryDateRangeChange($event)"
                  [nzPlaceHolder]="['開始日期', '結束日期']"
                  style="width: 100%;"
                ></nz-range-picker>
              </div>
            </div>
          </div>

          <div nz-col [nzLg]="8" [nzMd]="12" [nzSm]="24" [nzXl]="8" [nzXs]="24" [nzXXl]="6">
            <div nz-row>
              <div style="width: 100%;">庫存操作：</div>
              <div style="width: 100%; margin-top: 8px;">
                <nz-select
                  [(ngModel)]="searchParam.stock_action"
                  (ngModelChange)="onSearch()"
                  nzPlaceHolder="庫存操作"
                  nzAllowClear
                  style="width: 100%;"
                >
                  <nz-option nzValue="追貨" nzLabel="追貨"></nz-option>
                  <nz-option nzValue="清貨" nzLabel="清貨"></nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div nz-col [nzSpan]="24" style="margin-bottom: 16px;">
      <div nz-row align="middle" style="display: flex; align-items: center;">
        <div nz-col [nzSpan]="4" style="text-align: right; padding-right: 8px;">
          <span>庫存：</span>
        </div>
        <div nz-col [nzSpan]="16">
          <nz-slider
            [nzRange]="true"
            [nzMin]="minInventory"
            [nzMax]="maxInventory"
            [(ngModel)]="inventoryRange"
            (ngModelChange)="onInventoryRangeChange($event)"
            [nzMarks]="inventoryMarks"
          ></nz-slider>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="24" style="margin-bottom: 16px;">
      <div nz-row align="middle" style="display: flex; align-items: center;">
        <div nz-col [nzSpan]="4" style="text-align: right; padding-right: 8px;">
          <span>七日總配貨：</span>
        </div>
        <div nz-col [nzSpan]="16">
          <nz-slider
            [nzRange]="true"
            [nzMin]="minAverageDistribution"
            [nzMax]="maxAverageDistribution"
            [(ngModel)]="averageDistributionRange"
            (ngModelChange)="onAverageDistributionRangeChange($event)"
            [nzMarks]="averageDistributionMarks"
          ></nz-slider>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="24" style="margin-bottom: 16px;">
      <div nz-row align="middle" style="display: flex; align-items: center;">
        <div nz-col [nzSpan]="4" style="text-align: right; padding-right: 8px;">
          <span>七日平均銷售：</span>
        </div>
        <div nz-col [nzSpan]="16">
          <nz-slider
            [nzRange]="true"
            [nzMin]="minAverageSales"
            [nzMax]="maxAverageSales"
            [(ngModel)]="averageSalesRange"
            (ngModelChange)="onAverageSalesRangeChange($event)"
            [nzMarks]="averageSalesMarks"
          ></nz-slider>
        </div>
      </div>
    </div>

    <!-- <div nz-col [nzSpan]="24" style="margin-bottom: 16px;">
      <div nz-row align="middle">
        <div nz-col [nzSpan]="4">
          <span>毛利率範圍：{{ (marginRange[0] * 100).toFixed(0) }}% - {{ (marginRange[1] * 100).toFixed(0) }}%</span>
        </div>
        <div nz-col [nzSpan]="20">
          <nz-slider
            [nzRange]="true"
            [nzMin]="minMargin"
            [nzMax]="maxMargin"
            [(ngModel)]="marginRange"
            (ngModelChange)="onMarginRangeChange($event)"
            [nzStep]="0.01"
          ></nz-slider>
        </div>
      </div>
    </div> -->
    </ng-container>
    
  </div>
 
 
</nz-card>

<nz-card style="margin-top: 16px" [nzLoading]="isSpinning">
  <nz-page-header class="site-page-header" nzTitle="產品列表" style="padding-bottom: 0px">
    <nz-page-header-extra style="display: flex; justify-content: center; align-items: center">
      <nz-space>
        <i
          *nzSpaceItem
          class="hand-model"
          nz-icon
          nz-tooltip
          nzTheme="outline"
          nzTooltipTitle="顯示/隱藏操作"
          nzType="tool"
          (click)="toggleOperationsColumn()"
          style="margin-left: 12px; font-size: 16px"
        ></i>
        <i
          *nzSpaceItem
          class="hand-model"
          nz-icon
          nz-tooltip
          nzTheme="outline"
          nzTooltipTitle="重新加載"
          nzType="reload"
          (click)="reload()"
          style="margin-left: 12px; font-size: 16px"
        ></i>
        <i
          *nzSpaceItem
          class="hand-model"
          nz-icon
          nz-tooltip
          nzTheme="outline"
          nzTooltipTitle="密度"
          nzType="column-height"
          style="margin-left: 12px; font-size: 16px"
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="tableSizeMenu"
        ></i>
        <i
          *nzSpaceItem
          class="hand-model"
          nz-icon
          nz-popover
          nz-tooltip
          nzPopoverPlacement="bottomRight"
          nzPopoverTrigger="click"
          nzTheme="outline"
          nzTooltipTitle="列設置"
          nzType="setting"
          style="margin-left: 12px; font-size: 16px"
          [(nzPopoverVisible)]="settingsVisible"
          [nzPopoverContent]="contentTemplate"
        ></i>
      </nz-space>
    </nz-page-header-extra>
  </nz-page-header>
  <nz-spin [nzSpinning]="isSpinning">
    <nz-table
      #fixedTable
      nzShowSizeChanger
      [nzData]="ProductListFiltered"
      [nzSize]="tableSize"
      [nzTotal]="ProductList.length"
      [nzPageSizeOptions]="[10, 20, 30, 50, 100, 200, 500]"
      (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
    >
      <thead>
        <tr>
          <th
            *ngIf="showOperationsColumn"
            nzLeft
            [(nzChecked)]="checked"
            [nzIndeterminate]="indeterminate"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <ng-container *ngFor="let row of Product_rows">
            <th
              *ngIf="row.show"
              [nzSortOrder]="row.sortOrder"
              [nzSortFn]="row.sortFn"
              [nzSortDirections]="row.sortDirections"
              [nzLeft]="row.key === 'name'"
            >
              {{ row.name }}
            </th>
          </ng-container>
          <th *ngIf="permission.isViewAllProductsAndSuppliersAllowed && showOperationsColumn">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of fixedTable.data">
          <td *ngIf="showOperationsColumn" [nzChecked]="setOfCheckedId.has(product.id)" (nzCheckedChange)="onItemChecked(product.id, $event)"></td>
          <ng-container *ngFor="let row of Product_rows">
            <td *ngIf="row.show && !row.custom">
              {{ row.format ? row.format(product) : product[row.key] }}
            </td>
            <td *ngIf="row.show && row.key === 'name'" nzLeft>
              <!-- <a (click)="editProduct(product.id)" style="color: black;">{{ product.name }}</a> -->
              <a
                href="https://admin.shopify.com/store/flashgreenhk/products/{{ product.shopify_id }}"
                target="_blank"
                style="color: black"
              >
                {{ product.name }}
              </a>
            </td>
            <td *ngIf="row.show && row.key === 'gross_margin'">
              {{ (product.gross_margin * 100).toFixed(2) + "%" }}
            </td>
            <td *ngIf="row.show && row.key === 'has_long_term_supply'">
              {{ product.has_long_term_supply ? "是" : "否" }}
            </td>
            <td
              *ngIf="row.show && row.key === 'total_actual_transfer_quantity'"
              nz-popover
              [nzPopoverContent]="transferDetailTemplate"
              nzPopoverPlacement="topLeft"
              [nzPopoverTrigger]="
                product.average_daily_transfer === 0 || product.total_actual_transfer_quantity === 0 ? null : 'hover'
              "
            >
              <ng-container *ngIf="!isTransferDataLoading; else loadingTpl">
                {{ product.average_daily_transfer || 0 }} ({{ product.total_actual_transfer_quantity || 0 }})
              </ng-container>
              <ng-template #loadingTpl>
                <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
              </ng-template>
              <ng-template #transferDetailTemplate>
                <div style="display: flex; flex-direction: column; align-items: stretch">
                  <ng-container *ngFor="let transfer of getTransferDetails(product.shopify_id)">
                    <div style="display: flex; justify-content: space-between; margin-bottom: 8px">
                      <div style="margin-right: 48px">{{ formatDate(transfer.transfer_date) }}</div>
                      <div>{{ transfer.actual_transfer_quantity }}</div>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </td>
            <td
              *ngIf="row.show && row.key === 'last_seven_days_sales'"
              nz-popover
              [nzPopoverContent]="salesDetailTemplate"
              nzPopoverPlacement="topLeft"
              [nzPopoverTrigger]="product.last_seven_days_sales === 0 ? null : 'hover'"
            >
              <ng-container *ngIf="!isSalesDataLoading; else loadingTpl">
                {{ formatSalesWithAverage(product) }}
              </ng-container>
              <ng-template #loadingTpl>
                <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
              </ng-template>

              <ng-template #salesDetailTemplate>
                <div style="display: flex; flex-direction: column; align-items: stretch">
                  <!-- <div style="border-bottom: 1px solid #f0f0f0; margin-bottom: 8px; padding-bottom: 8px;">
                      <div>總銷售量：{{ product.last_seven_days_sales || 0 }}</div>
                      <div>平均每日：{{ formatSalesWithAverage(product).split('(')[1].replace(')', '') }}</div>
                    </div> -->
                  <ng-container *ngFor="let sale of getSalesDetails(product.id)">
                    <div style="display: flex; justify-content: space-between; margin-bottom: 8px">
                      <div style="margin-right: 48px">{{ formatDate(sale.sale_date) }}</div>
                      <div>{{ sale.quantity }}</div>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </td>
            <td *ngIf="row.show && row.key === 'status'">
              <nz-badge [nzText]="product[row.key]" [nzStatus]="product[row.key] === '有效' ? 'success' : 'error'" />
            </td>
            <!-- <td *ngIf="row.show && row.key === 'inventory_quantity'"
                nz-popover
                [nzPopoverContent]="inventoryDetailTemplate"
                nzPopoverPlacement="topLeft"
                [nzPopoverTrigger]="product.inventory_details?.length ? 'hover' : null">
                <ng-container *ngIf="!isInventoryDataLoading; else loadingTpl">
                  {{ product.inventory_quantity || 0 }}
                </ng-container>
                <ng-template #loadingTpl>
                  <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                </ng-template>
                <ng-template #inventoryDetailTemplate>
                  <div *ngIf="product.inventory_details">
                    <p>總庫存: {{ product.inventory_quantity }}</p>
                    <p>各地點庫存:</p>
                    <div *ngFor="let detail of product.inventory_details">
                      <p>{{ getLocationNameById(detail.locationId) }}: {{ detail.available }}</p>
                    </div>
                  </div>
                </ng-template>
            </td> -->
            <td *ngIf="row.show && row.key === 'view_inventory'">
              <ng-container *ngIf="!isLoadingInventory(product.shopify_id); else loadingIcon">
                <div
                  class="clickable-icon"
                  nz-popover
                  [nzPopoverContent]="inventoryDetailTemplate"
                  nzPopoverTrigger="click"
                  [nzPopoverVisible]="shouldShowPopover(product)"
                  (nzPopoverVisibleChange)="handlePopoverVisibility(product.shopify_id, $event)"
                  (click)="getInventoryAmount(product.shopify_id)"
                >
                  <ng-container *ngIf="product.inventory_quantity === null || product.inventory_quantity === undefined">
                    <span nz-icon nzType="search" nzTheme="outline"></span>
                  </ng-container>
                  <span
                    class="inventory-number"
                    *ngIf="product.inventory_quantity !== null && product.inventory_quantity !== undefined"
                  >
                    {{ product.inventory_quantity }}
                  </span>
                </div>
              </ng-container>

              <ng-template #loadingIcon>
                <span nz-icon nzType="loading" nzSpin class="loading-icon"></span>
              </ng-template>

              <ng-template #inventoryDetailTemplate>
                <div *ngIf="product.inventory_details && hasNonZeroInventory(product.inventory_details)">
                  <div *ngFor="let detail of filterValidLocations(product.inventory_details)">
                    <p *ngIf="detail.available !== 0">
                      {{ getLocationName(detail.locationId) }}: {{ detail.available }}
                    </p>
                  </div>
                </div>
              </ng-template>
            </td>
            <td *ngIf="row.show && row.key === 'total_inventory'"
                nz-popover
                [nzPopoverContent]="inventoryDetailTemplate"
                nzPopoverPlacement="top"
                [nzPopoverTrigger]="(product.location_inventories && product.location_inventories.length > 0) ? 'hover' : null">
                <ng-container *ngIf="!loadingInventory; else loadingTpl">
                    {{ product.total_inventory || 0 }}
                </ng-container>
                <ng-template #loadingTpl>
                    <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                </ng-template>
                
                <ng-template #inventoryDetailTemplate>
                    <div>
                        <div *ngFor="let item of product.location_inventories" style="margin-bottom: 8px;">
                            <div style="display: flex; justify-content: space-between;">
                                <span>{{ item.locationName }}:</span>
                                <span style="margin-left: 16px;">{{ item.available }}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </td>
            <td *ngIf="row.show && row.key === 'warehouse_inventory'">
                <ng-container *ngIf="!loadingInventory; else loadingTpl">
                    {{ product.warehouse_inventory || 0 }}
                </ng-container>
                <ng-template #loadingTpl>
                    <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                </ng-template>
            </td>

 
            <td *ngIf="row.show && row.key === 'inventory_value'">
                <ng-container *ngIf="!loadingInventory; else loadingTpl">
                    {{ row.format ? row.format(product) : '-' }}
                </ng-container>
                <ng-template #loadingTpl>
                    <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                </ng-template>
            </td>
            <td *ngIf="row.show && row.key === 'estimated_inventory_sales_days'">
                <ng-container *ngIf="!loadingInventory; else loadingTpl">
                    <span [style.color]="row.format ? row.format(product) === '-' ? 'inherit' : (calculateEstimatedDailySales(product) > 30 ? 'red' : 'inherit') : 'inherit'">
                        {{ row.format ? row.format(product) : '-' }}
                    </span>
                </ng-container>
                <ng-template #loadingTpl>
                    <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                </ng-template>
            </td>
            <td *ngIf="row.show && row.key === 'last_seven_days_sales_amount'">
             <ng-container *ngIf="!isSalesDataLoading; else loadingTpl">
             {{ row.format ? row.format(product) : '-' }}
              </ng-container>
            <ng-template #loadingTpl>
             <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
            </ng-template>
            </td>
            <!-- <td *ngIf="row.show && row.key === 'sales_details'">
              <ng-container *ngIf="!getAverageSales(product.id); else showAverage">
                <span 
                  nz-icon 
                  nzType="search" 
                  nzTheme="outline"
                  [nz-popover]
                  [nzPopoverTitle]="'銷售詳情'"
                  [nzPopoverContent]="salesDetailsTpl"
                  [nzPopoverTrigger]="'click'"
                  [nzPopoverVisible]="getPopoverVisible(product.id)"
                  (nzPopoverVisibleChange)="setPopoverVisible(product.id, $event)"
                  style="cursor: pointer;"
                  (click)="handleSalesDetailsClick(product.id)"
                ></span>
              </ng-container>
              <ng-template #showAverage>
                <span
                  [nz-popover]
                  [nzPopoverTitle]="'銷售詳情'"
                  [nzPopoverContent]="salesDetailsTpl"
                  [nzPopoverTrigger]="'click'"
                  [nzPopoverVisible]="getPopoverVisible(product.id)"
                  (nzPopoverVisibleChange)="setPopoverVisible(product.id, $event)"
                  style="cursor: pointer;"
                >
                  {{ getAverageSales(product.id) | number:'1.0-1' }}
                </span>
              </ng-template>
              
              <ng-template #salesDetailsTpl>
                <div style="min-width: 200px;">
                  <div *ngIf="isSalesDetailsLoading(product.id); else salesContent" 
                       style="display: flex; justify-content: center; padding: 20px;">
                    <nz-spin nzSimple></nz-spin>
                  </div>
                  
                  <ng-template #salesContent>
                    <div class="sales-details-content">
                      <div *ngIf="getDetailedSalesHistory(product.id); let salesHistory">
                        <div *ngIf="salesHistory.length > 0; else noSales">
                          <div *ngFor="let sale of salesHistory" class="sale-row">
                            <span class="date">{{formatSalesDate(sale.date)}}</span>
                            <span class="quantity">{{sale.quantity}}</span>
                          </div>
                        </div>
                        <ng-template #noSales>
                          <div>無銷售記錄</div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </td> -->
            <td *ngIf="row.show && row.key === 'days_until_expiry'"
                [style.color]="calculateDaysUntilExpiry(product.expiry_date?.toString()) !== null && 
                               calculateDaysUntilExpiry(product.expiry_date?.toString())! < 30 ? 'red' : 'inherit'">
                {{ row.format ? row.format(product) : '-' }}
            </td>
            <td *ngIf="row.show && row.key === 'restock_action'"
                [style.color]="'blue'"
                [style.text-align]="'center'">
                {{ row.format ? row.format(product) : '' }}
            </td>
            <td *ngIf="row.show && row.key === 'clearance_action'"
                [style.color]="'red'"
                [style.text-align]="'center'">
                {{ row.format ? row.format(product) : '' }}
            </td>
            <td *ngIf="row.show && row.key === 'required_daily_sales'">
                <ng-container *ngIf="!loadingInventory; else loadingTpl">
                    {{ row.format ? row.format(product) : '-' }}
                </ng-container>
                <ng-template #loadingTpl>
                    <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
                </ng-template>
            </td>
            <td *ngIf="row.show && row.custom && row.key.startsWith('location_sales_') && row.format">
              <ng-container *ngIf="!row.format(product); else showSales">
                <span 
                  [nz-popover]
                  [nzPopoverTitle]="'銷售詳情'"
                  [nzPopoverContent]="locationSalesDetailsTpl"
                  [nzPopoverTrigger]="'click'"
                  [nzPopoverVisible]="getLocationPopoverVisible(product.id, getLocationIdFromKey(row.key))"
                  (nzPopoverVisibleChange)="onLocationPopoverVisibleChange($event, product.id, getLocationIdFromKey(row.key))"
                  style="cursor: pointer;"
                >
                  <span 
                    *ngIf="!locationAveragesMap.has(product.id + '-' + getLocationIdFromKey(row.key))"
                    nz-icon 
                    nzType="search" 
                    nzTheme="outline"
                    (click)="handleLocationSalesClick(product.id, getLocationIdFromKey(row.key))"
                  ></span>
                  <span 
                    *ngIf="locationAveragesMap.has(product.id + '-' + getLocationIdFromKey(row.key))"
                    (click)="handleLocationSalesClick(product.id, getLocationIdFromKey(row.key))"
                  >
                    {{ locationAveragesMap.get(product.id + '-' + getLocationIdFromKey(row.key)) | number:'1.1-1' }}
                  </span>
                </span>
              </ng-container>
              <ng-template #showSales>
                {{ row.format(product) }}
              </ng-template>
            </td>
            <td *ngIf="row.show && row.key === 'average_sales'">
              <ng-container *ngIf="!loadingInventory; else loadingTpl">
                <span
                  [nz-popover]
                  [nzPopoverTitle]="'銷售詳情'"
                  [nzPopoverContent]="averageSalesDetailsTpl"
                  [nzPopoverTrigger]="'click'"
                  [nzPopoverVisible]="getPopoverVisible(product.id)"
                  (nzPopoverVisibleChange)="setPopoverVisible(product.id, $event)"
                  style="cursor: pointer;"
                  (click)="handleSalesDetailsClick(product.id)"
                >
                  {{ getAverageSalesValue(product) }}
                </span>
                
                <ng-template #averageSalesDetailsTpl>
                  <div style="min-width: 200px;">
                    <div *ngIf="isSalesDetailsLoading(product.id); else salesContent" 
                         style="display: flex; justify-content: center; padding: 20px;">
                      <nz-spin nzSimple></nz-spin>
                    </div>
                    
                    <ng-template #salesContent>
                      <div class="sales-details-content">
                        <div *ngIf="getDetailedSalesHistory(product.id); let salesHistory">
                          <div *ngIf="salesHistory.length > 0; else noSales">
                            <div *ngFor="let sale of salesHistory" class="sale-row">
                              <span class="date">{{formatSalesDate(sale.date)}}</span>
                              <span class="quantity">{{sale.quantity}}</span>
                            </div>
                          </div>
                          <ng-template #noSales>
                            <div>無銷售記錄</div>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #loadingTpl>
                <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
              </ng-template>
            </td>
          </ng-container>
          <td style="text-align: left; width: 140px;" *ngIf="permission.isViewAllProductsAndSuppliersAllowed && showOperationsColumn">
            <nz-space nzAlign="center" [nzSize]="4">
              <!-- <a *nzSpaceItem [routerLink]="['/products/detail', product.id]" style="display: inline-block; min-width: 32px;">查看</a> -->
              <!-- <ng-container *ngIf="permission.isEditProductAllowed"> -->
              <!-- <nz-divider *nzSpaceItem nzType="vertical" style="margin: 0 4px;"></nz-divider> -->
              <a
                *nzSpaceItem
                [routerLink]="['/products/update', product.id]"
                style="display: inline-block; min-width: 32px"
              >
                修改
              </a>
              <!-- </ng-container> -->
              <nz-divider *nzSpaceItem nzType="vertical" style="margin: 0 4px"></nz-divider>
              <a *nzSpaceItem (click)="confirmDelete(product.id)" style="display: inline-block; min-width: 32px">
                刪除
              </a>
            </nz-space>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-spin>
</nz-card>

<ng-template #contentTemplate>
  <div style="overflow-y: auto; max-height: 400px; padding-right: 8px;">
    <ul cdkDropList (cdkDropListDropped)="dropTableConfig($event)" style="padding-left: 0; margin: 0">
      <li *ngFor="let item of Product_rows" class="settings" cdkDrag style="padding: 4px 0 8px 0">
        <div>
          <i class="m-r-8 hand-model-move" cdkDragHandle nz-icon nzTheme="outline" nzType="drag"></i>
          <label nz-checkbox [(nzChecked)]="item.show" (nzCheckedChange)="changeSignalCheck($event, item)">
            {{ item.name }}
          </label>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<nz-dropdown-menu #tableSizeMenu="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li
      nz-menu-item
      *ngFor="let option of TABLE_SIZE_OPTIONS"
      (click)="tableSize = option.value"
      [nzSelected]="option.selected"
    >
      {{ option.sizeName }}
    </li>
  </ul>
</nz-dropdown-menu>

<nz-footer *ngIf="setOfCheckedId.size > 0 && showOperationsColumn">
  <div class="footer-bar flex">
    <div class="flex-1 footer-text">已選擇 {{ setOfCheckedId.size }} 個產品</div>
    <div>
      <button
        *ngIf="setOfCheckedId.size > 1"
        style="margin-left: 12px"
        (click)="confirmBatchDelete()"
        nz-button
        class="mr10"
      >
        批量刪除
      </button>
    </div>
  </div>
</nz-footer>

<nz-modal [(nzVisible)]="isDeleteModalVisible" nzTitle="確認刪除" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <p>確定要刪除這個產品嗎？</p>
</nz-modal>

<nz-modal
  [(nzVisible)]="isBatchDeleteModalVisible"
  nzTitle="確認批量刪除"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleBatchOk()"
>
  <p>確定要刪除這些產品嗎？</p>
</nz-modal>

<ng-template #locationSalesDetailsTpl>
  <div *ngIf="isLocationOrderLoading; else salesContent" 
       style="display: flex; justify-content: center; padding: 20px;">
    <nz-spin nzSimple></nz-spin>
  </div>
  
  <ng-template #salesContent>
    <div *ngIf="selectedLocationSales.length > 0" style="max-height: 300px; overflow-y: auto;">
      <table style="width: 100%;">
        <tbody>
          <tr *ngFor="let sale of selectedLocationSales">
            <td style="padding: 8px;">{{ sale.date }}</td>
            <td style="padding: 8px; text-align: right;">{{ sale.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="selectedLocationSales.length === 0">
      暫無銷售記錄
    </div>
  </ng-template>
</ng-template>


